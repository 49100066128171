<div class="bg-gradient">
    <div class="bg-photo"></div>

    <div class="bg-gradient-2"></div>

    <div class="logo-motion">
        <!--Este es el logo-->
        <a href="#"
            ><img
                src="assets/images/motion-login_mi-sim-icon.svg"
                alt="motion-logo"
        /></a>
    </div>

    <div>
        <form
            [formGroup]="loginForm"
            (ngSubmit)="loginByAuth()"
            class="form-card white-card"
        >
            <div class="input-div">
                <label>Usuario*</label>
                <input
                    formControlName="email"
                    type="text"
                    name="Usuario"
                    placeholder="Nombre de usuario"
                />
            </div>

            <div class="input-div">
                <label>Clave de acceso*</label>
                <input
                    formControlName="password"
                    minlength="6"
                    type="password"
                    name="Contraseña"
                    placeholder="Contraseña"
                />
            </div>

            <div class="form-selections">
                <a class="red" href="#">He olvidado mi contraseña</a><br />

                <button class="btn1" name="Ingresar" [type]="'submit'">
                    Sign In</button
                ><br />

                <a class="red" href="#">Registrarme</a>
            </div>
        </form>
    </div>

    <div class="copyright">
        <a class="red" href="#"
            ><span>&copy; 2020 Copyright / </span> A.B Comercial LTDA</a
        >
    </div>
</div>

import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';
import xml2js from 'xml2js';
import { Cdr } from '@/models/Cdr';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public messages: any = null;
  public messages_count: number = null;
  public records: any[] = null;
  public records_count: number = null;
  public cards: any = null;
  public cards_count: any = null;
  public response_status: any = null;
  public response_count: any = null;
  public transaction_result: any = null;
  public transaction_count: any = null;
  public myAccount: any = null;
  public balance_month: number = null;

  public notifications: any = null;

  public notifications_new: number = null;
  public notifications_total: number = null;

  title = 'read-xml-angular8';
  public xmlItems: any;

  constructor(private http: HttpClient) {
  }

  async getMyAccountInfo() {
    let MyAccount = await this.http
      .get<any>(`/api/misim/myaccount`)
      .toPromise();
    if (MyAccount) {
      this.myAccount = MyAccount;
      return MyAccount;
    } else {
      return null;
    }
  }

  async postMyAccountInfo(data) {
    let body = {
      jsonrpc: '2.0',
      params: {
        db: 'plataforma_motion',
        name: data.name,
        email: data.email,
        commercial_company_name: data.company_name,
        phone: data.telefono,
        zip: data.postal,
        image: data.image
      }
    };
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': 'http://localhost:3005'
    });
    let MyAccount = await this.http
      .post<any>(`/api/misim/myaccount/update`, body, { headers: headers })
      .toPromise();
    return MyAccount;
  }

  async getMessageInfo(page?: number, pageSize?: number, searchby?: string) {
    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*'
    });
    let messages = await this.http
      .get<any>(
        `/api/misim/messages/${page ? `page=${page}` : ''}${pageSize ? `&pagesize=${pageSize}` : ''
        }${searchby ? `&searchby=${searchby}` : ''}`,
        {
          headers: headers
        }
      )
      .toPromise();
    let messageTruphone: any;
    let messageTopConnect: any;
    var url = `/api/misim/send_sms/status/truphone/${page ? `page=${page}` : ''}${pageSize ? `&pagesize=${pageSize}` : ''
  }${searchby ? `&searchby=${searchby}` : ''}`;
    let sendSms = await this.http.get<any>(url).toPromise();
    if (sendSms.result != null) {
      let messageTruphoneFormated = sendSms.result.map(msg => {
        msg = {
          'date': msg.dateSubmitted,
          'msg': msg.content,
          'from': '',
          'to': msg.iccid
        }
        return msg
      });
      messageTruphone = messageTruphoneFormated;
    } else {
      console.log("No llegó respuesta getSendSms");
    }
    if (messages.result != null) {
      messageTopConnect = messages.result;
    }
    this.messages = { "result": [...messageTruphone, ...messageTopConnect], "count": messages.count + sendSms.total_send_sms_count };
    this.messages_count = messages.count + sendSms.total_send_sms_count;
    return { "result": [...messageTruphone, ...messageTopConnect], "count": messages.count + sendSms.total_send_sms_count }
  }

  async getCDR(
    page?: number,
    pageSize?: number,
    sim?: string,
    startdate?: Date,
    enddate?: Date,
    report_type?: string
  ) {

    let records = await this.http
      .get<any>(
        `/api/topconnect/getRecords/${page ? `page=${page}` : ''}${pageSize ? `&pagesize=${pageSize}` : ''
        }${page ? `&startdate=${startdate}` : `startdate=${startdate}`
        }&enddate=${enddate
        }${sim ? `&sim=${sim}` : ''}&reporttype=${report_type}`
      )
      .toPromise();

    if (records.result != null) {
      this.records = records.result;
      this.records_count = records.count;
      return records;
    } else {
      return null;
    }
  }

  async getSimreset(sim?: string) {
    let response = await this.http
      .get<any>(`/api/topconnect/reconnect_network/sim=${sim}`)
      .toPromise();
    if (response.result != null) {
      return response;
    } else {
      return null;
    }
  }

  async sendMessage(data) {
    let body = {
      jsonrpc: '2.0',
      params: {
        from: data.from,
        to: data.to,
        msg: data.msg
      }
    };
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    let MyAccount = await this.http
      .post<any>(`/api/topconnect/send`, body, { headers: headers })
      .toPromise();
    return MyAccount;
  }

  async transferBalance(data) {
    let body = {
      jsonrpc: '2.0',
      params: {
        type: data.type,
        sim: data.sim,
        value: data.value
      }
    };
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    });
    let Balance = await this.http
      .post<any>(`/api/topconnect/transaction`, body, { headers: headers })
      .toPromise();
    return Balance;
  }

  async getCardsInfo(
    page?: number,
    pageSize?: number,
    searchby?: string,
    sortby?: string,
    order?: string,) {

    var url = `/api/misim/cards/`;
    var params = '';
    if (page && pageSize) {
      params = params + `page=${page}&pagesize=${pageSize}`;
    }
    if (searchby) {
      params = params + `&searchby=${searchby}`;
    }
    if (sortby) {
      params = params + `&sortby=${sortby}`;
    }
    if (order) {
      params = params + `&order=${order}`;
    }
    let cards = await this.http.get<any>(url + params).toPromise();
    if (cards.result != null) {
      this.cards = cards.result;
      this.cards_count = cards.count;
      return cards;
    } else {
      return null;
    }
  }

  async getSimStatus(sim: string) {
    let response = await this.http
      .get<any>(
        `/api/topconnect/sim_card_status/sim=${sim}`
      )
      .toPromise();
    if (response.result != null) {
      this.response_status = response.result;
      this.response_count = response.count;
      return response;
    } else {
      return null;
    }
  }

  async getInfoTransaction(sim: string) {
    let response = await this.http
      .get<any>(
        `/api/misim/sim/transaction_info/sim=${sim}`
      )
      .toPromise();
    if (response.result != null) {
      this.transaction_result = response.result;
      this.transaction_count = response.count;
      return response;
    } else {
      return null;
    }
  }

  async getBalanceMonth() {
    let balance = await this.http
      .get<any>(`/api/topconnect/getBalance`)
      .toPromise();
    if (balance.result != null) {
      this.balance_month = balance.result;
      return balance;
    } else {
      return null;
    }
  }

  async getNotifications() {
    let notifications = await this.http
      .get<any>(`/api/misim/notifications/`)
      .toPromise();
    if (notifications.result != null) {
      this.notifications = notifications.result;
      this.notifications_new = notifications.count_new;
      this.notifications_total = notifications.total_count;
      return notifications;
    } else {
      return null;
    }
  }

  async postNotifications(id: number) {
    let body = {
      jsonrpc: '2.0',
      params: {
        notification: id
      }
    };
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    });
    let ViewNotification = await this.http
      .post<any>(`/api/misim/notification`, body, { headers: headers })
      .toPromise();
    return ViewNotification;
  }

  async updateSimLabel(data) {
    let body = {
      jsonrpc: '2.0',
      params: data
    };
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    });
    let updatedLabel = await this.http
      .post<any>(`/api/misim/update/label`, body, { headers: headers })
      .toPromise();
    return updatedLabel;
  }

  async updateCorp(data) {
    let body = {
      jsonrpc: '2.0',
      params: data
    };
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    });
    let Balance = await this.http
      .post<any>(`/api/misim/sim/updatecorp`, body, { headers: headers })
      .toPromise();
    return Balance;
  }

  async getBalanceTransfer(type: string, value: string) {
    var url = `/api/misim/${type}/balance/${value}`;
    let res = await this.http
      .get<any>(url)
      .toPromise();
    if (res != null) {
      return res
    } else {
      return null
    }
  }

  async getTruphoneCardsInfo(
    page?: number,
    pageSize?: number,
    searchby?: string,
    sortby?: string,
    order?: string,
  ) {
    var url = `/api/misim/cards/truphone/`;
    var params = '';
    if (page && pageSize) {
      params = params + `page=${page}&pagesize=${pageSize}`;
    }
    if (searchby) {
      params = params + `&searchby=${searchby}`;
    }
    if (sortby) {
      params = params + `&sortby=${sortby}`;
    }
    if (order) {
      params = params + `&order=${order}`;
    }
    let cards = await this.http.get<any>(url + params).toPromise();
    if (cards.result != null) {
      console.log(cards.result);
      this.cards = cards.result;
      this.cards_count = cards.total_cards_count;
      return cards;
    } else {
      console.log("No llegó respuesta");
      return null;
    }
  }

  async getSendSms() {
    var url = `/api/misim/send_sms/status/truphone/`;
    let sendSms = await this.http.get<any>(url).toPromise();
    if (sendSms.result != null) {
      return sendSms;
    } else {
      console.log("No llegó respuesta getSendSms");
      return null;
      ;
    }
  }

  async getOngoingSessionsSims() {
    var url = `/api/misim/ongoing_sessions_sims/truphone/`;
    let sessions = await this.http.get<any>(url).toPromise();
    if (sessions.result != null) {
      console.log(sessions);
    } else {
      console.log("No llegó respuesta getOngoingSessionsSims");
      ;
    }
  }

  async getDevices() {
    var url = `/api/misim/devices/status/truphone/`;
    let devices = await this.http.get<any>(url).toPromise();
    if (devices.result != null) {
      console.log(devices);
    } else {
      console.log("No llegó respuesta getDevices");
      ;
    }
  }

  async getSmsDetails(id: number) {
    var url = `/api/misim/sms_details/status/truphone/id=${id}`;
    let smsDetails = await this.http.get<any>(url).toPromise();
    if (smsDetails.result != null) {
      console.log(smsDetails);
    } else {
      console.log("No llegó respuesta getSmsDetails");
      ;
    }
  }

  async getSimCardDetails(iccid: string) {
    var url = `/api/misim/sim_card_details/status/truphone/iccid=${iccid}`;
    let SimCardDetails = await this.http.get<any>(url).toPromise();
    if (SimCardDetails.result != null) {
      console.log(SimCardDetails);
    } else {
      console.log("No llegó respuesta getSimCardDetails");
      ;
    }
  }

  async getSimCardStatus(iccid: string) {
    var url = `/api/misim/sim_card_status/status/truphone/iccid=${iccid}`;
    let SimCardStatus = await this.http.get<any>(url).toPromise();
    if (SimCardStatus.result != null) {
      console.log(SimCardStatus);
    } else {
      console.log("No llegó respuesta getSimCardStatus");
      ;
    }
  }

  async getCardsTruphoneCdrs(iccid: string) {
    var url = `/api/misim/cards/truphone/cdrs/iccid=${iccid}`;
    let CardsTruphoneCdrs = await this.http.get<any>(url).toPromise();
    if (CardsTruphoneCdrs.result != null) {
      console.log(CardsTruphoneCdrs);
    } else {
      console.log("No llegó respuesta getCardsTruphoneCdrs");
      ;
    }
  }

  async getCallDetailRecordDetails(cdr_id: number, iccid: string) {
    var url = `/api/misim/Call_Detail_Record_Details/status/truphone/iccid=${iccid}&cdr_id=${cdr_id}`;
    let CallDetailRecordDetails = await this.http.get<any>(url).toPromise();
    if (CallDetailRecordDetails.result != null) {
      console.log(CallDetailRecordDetails);
    } else {
      console.log("No llegó respuesta getCallDetailRecordDetails");
      ;
    }
  }

  async sendMessageTruphone(msg: string, iccid: string) {
    var url = `/api/misim/send_sms/truphone/?iccid=${iccid}&msg=${msg}`;
    let SendSmsTruphone = await this.http.get<any>(url).toPromise();
    if (SendSmsTruphone.result != null) {
      return SendSmsTruphone
    } else {
      console.log("No llegó respuesta getSendSmsTruphone");
      return SendSmsTruphone;
      ;
    }
  }

}

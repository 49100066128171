<!-- Sidebar -->
<div class="sidebar sidebar-white">
  <!-- Sidebar Menu -->
  <nav class="mt-2" style="overflow-y: hidden">
    <ul
      class="nav nav-pills nav-sidebar flex-column"
      data-widget="treeview"
      role="menu"
      data-accordion="false"
    >
      <!-- Add icons to the links using the .nav-icon class
       with font-awesome or any other icon font library -->
      <li class="nav-item">
        <a
          [routerLink]="['/']"
          class="nav-link"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{exact: true}"
        >
          <svg
            version="1.1"
            id="listos"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            style="enable-background: new 0 0 100 100"
            xml:space="preserve"
            class="nav-icon"
          >
                        <path
                          class="color-path"
                          d="M92,8v60H8V8H92 M100,0H0v76h100V0L100,0z M70,88H30v12h40V88z M44,24h-8v36h8V24z M26,36h-8v24h8V36z M64,32
                h-8v28h8V32z M82,16h-8v44h8V16z"
                        />
                    </svg>
          <p>Dashboard</p>
        </a>
      </li>
      <li class="nav-item">
        <a
          [routerLink]="['/account']"
          class="nav-link"
          routerLinkActive="active"
        >
          <svg
            version="1.1"
            id="listos"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            style="enable-background: new 0 0 100 100"
            xml:space="preserve"
            class="nav-icon"
          >
                        <path
                          class="color-path"
                          d="M50,12c6.6,0,12,5.4,12,12s-5.4,12-12,12s-12-5.4-12-12S43.4,12,50,12 M50,0C36.7,0,26,10.7,26,24
                s10.7,24,24,24s24-10.7,24-24S63.3,0,50,0L50,0z M50,56C24.4,56,3.3,75.2,0.4,100h12.1C15.4,81.9,31.1,68,50,68s34.6,13.9,37.5,32
                h12.1C96.7,75.2,75.6,56,50,56z"
                        />
                    </svg>
          <p>Mi cuenta</p>
        </a>
      </li>
      <li class="nav-item">
        <a
          [routerLink]="['/sim']"
          class="nav-link"
          routerLinkActive="active"
        >
          <svg
            version="1.1"
            id="listos"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            style="enable-background: new 0 0 100 100"
            xml:space="preserve"
            class="nav-icon"
          >
                        <path
                          class="color-path"
                          d="M54,84h-8V48h8V84z M69,48h-8v36h8V48z M39,48h-8v36h8V48z M77,8H42.3L23,27.3V92h54V8 M85,0v100H15V24L39,0H85
                L85,0z"
                        />
                    </svg>
          <p>Simcards</p>
        </a>
      </li>
      <li class="nav-item">
        <a
          [routerLink]="['/sim-with-plan']"
          class="nav-link"
          routerLinkActive="active"
        >
          <svg
            version="1.1"
            id="listos"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            style="enable-background: new 0 0 100 100"
            xml:space="preserve"
            class="nav-icon"
          >
                        <path
                          class="color-path"
                          d="M54,84h-8V48h8V84z M69,48h-8v36h8V48z M39,48h-8v36h8V48z M77,8H42.3L23,27.3V92h54V8 M85,0v100H15V24L39,0H85
                L85,0z"
                        />
                    </svg>
          <p>Simcards con plan </p>
        </a>
      </li>
      <li class="nav-item">
        <a
          [routerLink]="['/messages']"
          class="nav-link"
          routerLinkActive="active"
        >
          <svg
            version="1.1"
            id="listos"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            style="enable-background: new 0 0 100 100"
            xml:space="preserve"
            class="nav-icon"
          >
                        <path
                          class="color-path"
                          d="M60,72v18c0,0.8-0.1,1.5-0.3,2.3c-1,4.4-5,7.8-9.7,7.8H10c-4.8,0-8.7-3.3-9.7-7.8C0.1,91.5,0,90.8,0,90V10
                C0,4.5,4.5,0,10,0h40c4.8,0,8.9,3.4,9.8,8H10c-1.1,0-2,0.9-2,2v78h44V72H60z M80,36H40v8h40V36z M92,24H28v28v8.7l2.3-2.3l2.3-2.3
                H36h56V24 M100,16v48H36L20,80V52V16H100L100,16z"
                        />
                    </svg>
          <p>Mensajes</p>
        </a>
      </li>
      <li class="nav-item">
        <a
          [routerLink]="['/excel-report']"
          class="nav-link"
          routerLinkActive="active"
        >
          <svg
            version="1.1"
            id="listos"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            style="enable-background: new 0 0 100 100"
            xml:space="preserve"
            class="nav-icon"
          >
                        <path
                          class="color-path"
                          d="M88,56c0,24.3-19.7,44-44,44S0,80.3,0,56s19.7-44,44-44v8C24.2,20,8,36.2,8,56s16.2,36,36,36s36-16.2,36-36H88z
                        M64,14.1V36h21.9C82.3,25.8,74.2,17.7,64,14.1 M52,0c26.5,0,48,21.5,48,48H52V0L52,0z"
                        />
          </svg>

          <p>Reporte</p>
        </a>
      </li>
      <li class="nav-item">
        <a
          [routerLink]="['/sim-doctor']"
          class="nav-link"
          routerLinkActive="active"
        >


          <svg
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 1080 1080"
            style="enable-background:new 0 0 1080 1080;"
            class="nav-icon"
            xml:space="preserve">
              <style type="text/css">
                .st0{fill:#C5C5C5;}
              </style>
                          <path class="st0" d="M843,296.4v217.9l15.9-15.9l0,0V762c0,74-60.2,134.2-134.2,134.2H342.9c-74,0-134.2-60.2-134.2-134.2V498.3
                l16,16V296.4l-54.5,60c-27.6,30.4-26.5,77,2.6,106.1l20.2,20.2l0,0v279.5c0,82.6,67.3,150,150,150h182
                c0,79.6,64.7,136.5,144.4,136.5h86.5h-0.2c3.7,17.9,19.5,31.4,38.5,31.4c21.6,0,39.3-17.6,39.3-39.3c0-21.6-17.6-39.3-39.3-39.3
                c-18.9,0-34.8,13.5-38.5,31.4l0,0l0,0l0,0h-86.2c-70.8,0-128.6-49.8-128.6-120.7h184.1c82.6,0,150-67.3,150-150V482.7l0,0l20.2-20.2
                c29.1-29.1,30.2-75.7,2.6-106.1L843,296.4z M793.9,1017.1c12.9,0,23.5,10.6,23.5,23.5s-10.6,23.5-23.5,23.5s-23.5-10.6-23.5-23.5
                S780.9,1017.1,793.9,1017.1z"/>
                          <line class="st0" x1="586.9" y1="610.2" x2="586.9" y2="556"/>
                          <polyline class="st0" points="586.9,556 249.1,556.1 249.1,187.5 687,187.5 819.9,320.4 819.9,556 773.9,556 773.9,610.2
                872.8,610.2 872.8,298.3 710.2,135.6 194.9,135.6 194.9,610.2 586.9,610.2 "/>
                          <path class="st0" d="M540,674.7c-235.7,0-429.9,177-457.2,405.3h111.5C220.9,913.2,365.8,785.2,540,785.2S859.1,913.2,885.6,1080
                h111.5C969.9,851.7,775.7,674.7,540,674.7z"/>
                          <rect x="662" y="505.4" class="st0" width="34.5" height="155.2"/>
                          <rect x="726.7" y="505.4" class="st0" width="34.5" height="155.2"/>
                          <rect x="597.3" y="505.4" class="st0" width="34.5" height="155.2"/>
          </svg>

          <p>SimDoctor</p>
        </a>
      </li>
    </ul>
  </nav>
</div>

<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h2 class="title-main m-0">Generar Informe</h2>
            </div>
            <!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item">
                        <a class="home" [routerLink]="['/']">Home</a>
                    </li>
                    <li class="breadcrumb-item active">Generar Informe</li>
                </ol>
            </div>
            <div class="page-subheading">
                <div class="subtitle">
                    En esta sección puede generar un reporte, configure los
                    parametros requeridos a continuación.
                </div>
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>

<div class="content-general column">
    <div class="content-Segundario column">
        <div class="header">
            <div class="title">HISTORIAL</div>
        </div>
        <form
            class="form column"
            [formGroup]="recordsForm"
            (ngSubmit)="getRecords()"
            *ngIf="showForm"
        >

            <div class="flex-direction">
                <div class="content-type column col-6">
                    <div class="page-subheading-subtitle">
                        Fecha de historial
                    </div>
                    <div class="column date">
                        <div class="item-type">
                            <div class="title-type">Desde</div>
                            <input
                                type="date"
                                class="input-date"
                                formControlName="startdate"
                                [disabled]="!minDate"
                                min="{{ minDate }}"
                                max="{{ maxDate }}"
                            />
                        </div>
                        <div class="item-type">
                            <div class="title-type">Hasta</div>
                            <input
                                type="date"
                                class="input-date"
                                formControlName="enddate"
                                min="{{ minDate }}"
                                max="{{ maxDate }}"
                            />
                        </div>
                    </div>
                </div>
                <ng-container
                    *ngIf="!loadingSimcards && typeTableHistory !== 'reload_history'"
                    class="col-6 column content-card"
                >
                    <div class="content-type column col-6">
                        <div class="page-subheading-subtitle2">
                            Seleccione las simcards
                        </div>
                        <div class="add-input column">
                            <div class="select_all_simcards">
                                <div>
                                    <a
                                        type=""
                                        class="ec-link1"
                                        id="select_all_simcards"
                                        name="select_all_simcards"
                                        (click)="selectAllUnits()"
                                        >Seleccionar todo</a
                                    >
                                </div>
                                <div>
                                    <a
                                        type=""
                                        class="ec-link2"
                                        id="delete_all_simcards"
                                        name="delete_all_simcards"
                                        (click)="deleteAllUnits()"
                                        >Borrar todo</a
                                    >
                                </div>
                            </div>
                            <div>
                                <mat-select
                                    type="text"
                                    multiple
                                    class="i-select i-select-material"
                                    placeholder="Simcards"
                                    [formControl]="SimcardsForm"
                                    [disabled]="sim_select"
                                >
                                    <input
                                        (keyup)="searchSim($event.target.value)"
                                        placeholder="Buscar sim.."
                                        class="search-select"
                                    />
                                    <mat-option
                                        *ngFor="let card of SelectSimcards"
                                        [value]="card.onum"
                                        >{{ card.onum }}</mat-option
                                    >
                                </mat-select>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="footer">
              <div class="report-type">
                <label class="report_type">Tipo de reporte</label>
                <select
                  class="form-select form-select-sm"
                  aria-label=".form-select-sm example"
                  formControlName="report_type"
                  (change)="onChageReportType($event.target.value)"
                >
                  <option value="dcdr">Historial de datos</option>
                  <option value="cdr">Historial de red</option>
                  <option value="transactions">Transacciones</option>
                  <!--<option value="reload_history">Auto Recargas Cuenta</option>-->
                </select>
              </div>
                <div class="button-content row">
                    <button class="btn2" [type]="'submit'">
                        Generar reporte
                    </button>
                </div>
            </div>
        </form>
        <ng-container *ngIf="showTableHistory">
          <app-table
            [model]="dataModel"
            [removeCard]="true"
            [hideIndex]="true"
            [hideHeader]="false"
            [showReturnReport]="true"
            (clickedReturn)="clickReturn($event)"
          ></app-table>
        </ng-container>
    </div>
</div>





<ng-container *ngIf="loading">
    <app-modal-loading [loading]="true"> </app-modal-loading>
</ng-container>
<ng-container *ngIf="created">
    <app-modal-loading [success]="true" [title]="title"> </app-modal-loading>
</ng-container>
<ng-container *ngIf="error">
    <app-modal-loading [error]="true" title="title"> </app-modal-loading>
</ng-container>

<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h2 class="title-main m-0">Mensajes</h2>
            </div>
            <!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/']">Home</a>
                    </li>
                    <li class="breadcrumb-item active">Mensajes</li>
                </ol>
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
</div>

<ng-template #actionsTemplate let-index="index" let-$implicit="$implicit">
    <div class="row m-0 p-0 w-100">
        <div
            class="d-flex align-items-center justify-content-center h-100 w-100"
        >
            <a class="btn btn-icon btn-light btn-sm">
                <span
                    [inlineSVG]="'./assets/icons/General/Settings-1.svg'"
                    class="svg-icon svg-icon-md svg-icon-primary"
                ></span>
            </a>
            <a matTooltip="Editar" class="btn btn-icon btn-light btn-sm mx-3">
                <span
                    [inlineSVG]="'./assets/icons/Communication/Write.svg'"
                    class="svg-icon svg-icon-md svg-icon-warning"
                ></span>
            </a>
        </div>
    </div>
</ng-template>
<app-table
    [model]="messageModel"
    [removeCard]="true"
    (paginatorEvent)="paginatorEvent($event)"
    (pageSizeEvent)="pageSizeEvent($event)"
    [hideIndex]="true"
    (searchEvent)="searchP($event)"
    (getRecords)="getTotalMessages($event)"
></app-table>

<!--INICIO MODAL-->
<ng-template #content let-modal>
  <div class="modal-header" *ngIf="modal.modalTitle">
    <h3 class="kt-subheader__title">
      {{ model ? model.modalTitle : 'Buscador' }}
    </h3>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row align-items-center">
      <div class="col-12">
        <ng-container [ngTemplateOutlet]="tableTemplate"></ng-container>
      </div>
    </div>
  </div>
</ng-template>
<!--FIN MODAL-->

<!--FORMAS DE VISUALIZAR-->
<!-- <button
    *ngIf="model.isModal"
    class="btn btn-sm btn-light-success font-weight-bolder text-uppercase"
    type="button"
    (click)="open(content)"
>
    {{ model ? model.buttonTitle : 'Buscar' }}
</button> -->
<ng-container
  *ngIf="!model.isModal"
  [ngTemplateOutlet]="tableTemplate"
></ng-container>

<ng-container
  *ngIf="model.isModal"
  [ngTemplateOutlet]="tableTemplateModal"
  style="width: 100%"
></ng-container>
<!--FIN FORMAS DE VISUALIZAR-->

<!--begin::Card-->
<ng-template #tableTemplate>
  <div *ngIf="!removeCard" class="card-custom">
    <!--begin::Header-->
    <div
      *ngIf="model.title"
      class="card-header-table flex-wrap border-0 pt-6 pb-0"
    >
      <div class="card-title">
        <h3 *ngIf="model.title" class="card-label">
          {{ model ? model.title : 'Cargando...' }}
          <span
            *ngIf="model.description"
            class="d-block text-muted pt-2 font-size-sm"
          >{{ model ? model.description : '' }}</span
          >
        </h3>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body">
      <ng-container [ngTemplateOutlet]="datatableTemplate"></ng-container>
    </div>
    <!--end::Body-->
  </div>

  <ng-container
    *ngIf="removeCard"
    [ngTemplateOutlet]="datatableTemplate"
  ></ng-container>
</ng-template>
<!--end::CARD-->

<!--Table Modal-->
<ng-template #tableTemplateModal>
  <div *ngIf="!removeCard" class="card-custom">
    <!--begin::Body-->
    <div class="card-body">
      <ng-container
        [ngTemplateOutlet]="datatableTemplateModal"
      ></ng-container>
    </div>
    <!--end::Body-->
  </div>

  <ng-container
    *ngIf="removeCard"
    [ngTemplateOutlet]="datatableTemplateModal"
  ></ng-container>
</ng-template>
<!--end::CARD-->

<!--begin: Datatable-->
<ng-template #datatableTemplate>
  <section class="content">
    <div class="container-fluid">
      <div class="main-card mb-3 mt-3">
        <div class="card-header-table">
          <ng-container *ngIf="model?.records?.length >= 0 && !hideHeader">
            <app-pagination
              [model]="model"
              [pagination]="model.pagination"
              [title]="model.title"
              [showPageSize]="showPageSize"
              [showReturnReport]="showReturnReport"
              (clickedReturn)="onClickReturn($event)"
              (clickedPage)="clickedPage($event)"
              (clickedPageSize)="clickedPageSize($event)"
              (changeSearch)="changeSearch($event)"
              (getTotalRecords)="getTotalRecords($event)"
            ></app-pagination>
          </ng-container>

        </div>
        <ng-container>
          <div>
            <div
              *ngIf="model?.records?.length > 0"
              class="grid table-container table-misim"
              [ngClass]="{'table-misim-report': !showPageSize && showReturnReport}"
              id="kt_datatable"
            >
              <ng-container *ngIf="showTableName">
                <p>{{tableName}}</p>
              </ng-container>
              <table>
                <thead>
                <tr class="header">

                  <th
                    *ngIf="!hideIndex"
                    data-field="RecordID"
                    data-sort="asc"
                  >
                    <span>#</span>
                  </th>

                  <th
                    *ngFor="let field of model.fields"
                    data-field="Actions"
                    data-autohide-disabled="false"
                    (click)="updateFilterStatus(field.key, field.filterOrder)"
                  >
                                            <span>
                                                <ng-container
                                                  *ngIf="!field.filter"
                                                >
                                                    <span>
                                                        {{ field.title }}
                                                    </span>
                                                </ng-container>
                                                <ng-container *ngIf="field.filter">
                                                    <a class="field_title_content">
                                                      <span>
                                                          {{ field.title }}
                                                      </span>
                                                      <ng-container *ngIf="field.filterOrder === 'desc'">
                                                        <svg class="filter-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M182.6 470.6c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8H288c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128z"/></svg>
                                                      </ng-container>
                                                      <ng-container *ngIf="field.filterOrder === 'asc'">
                                                        <svg class="filter-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M182.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8H288c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-128-128z"/></svg>
                                                      </ng-container>
                                                      <ng-container *ngIf="field.filterOrder === 'none'">
                                                        <svg class="filter-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M3.9 54.9C10.5 40.9 24.5 32 40 32H472c15.5 0 29.5 8.9 36.1 22.9s4.6 30.5-5.2 42.5L320 320.9V448c0 12.1-6.8 23.2-17.7 28.6s-23.8 4.3-33.5-3l-64-48c-8.1-6-12.8-15.5-12.8-25.6V320.9L9 97.3C-.7 85.4-2.8 68.8 3.9 54.9z"/></svg>
                                                      </ng-container>
                                                      <ng-container *ngIf="!field.filterOrder">
                                                        <svg class="filter-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M3.9 54.9C10.5 40.9 24.5 32 40 32H472c15.5 0 29.5 8.9 36.1 22.9s4.6 30.5-5.2 42.5L320 320.9V448c0 12.1-6.8 23.2-17.7 28.6s-23.8 4.3-33.5-3l-64-48c-8.1-6-12.8-15.5-12.8-25.6V320.9L9 97.3C-.7 85.4-2.8 68.8 3.9 54.9z"/></svg>
                                                      </ng-container>
                                                    </a>
                                                </ng-container>
                                            </span>
                  </th>
                  <th
                    *ngIf="model.enableActions"
                    data-field="Actions"
                    data-autohide-disabled="false"
                  >
                    <span>Acciones</span>
                  </th>
                </tr>
                </thead>
                <tbody *ngIf="model?.records?.length > 0">
                <ng-container
                  *ngFor="
                                            let record of model.records;
                                            let i = index
                                        "
                >
                  <tr data-row="10" class="tr-table">
                    <td
                      *ngIf="!hideIndex"
                      (click)="clickedRow(i)"
                    >
                      <a>
                        <i
                          class="fa fa-caret-{{
                                                            selectedRow == i
                                                                ? 'down'
                                                                : 'right'
                                                        }}"
                        ></i>
                      </a>
                    </td>

                    <td
                      *ngIf="!hideIndex"
                      (click)="clickedRow(i)"
                      data-field="RecordID"
                      aria-label="11"
                    >
                                                <span
                                                ><span>{{
                                                  i +
                                                  1 +
                                                  (model.pagination
                                                      .currentPage -
                                                    1) *
                                                  model.pagination
                                                    .pageSize
                                                  }}</span></span
                                                >
                    </td>

                    <td
                      (click)="clickedRow(i)"
                      *ngFor="
                                                    let field of model.fields
                                                "
                      aria-label="Littel and Sons"
                    >
                      <ng-container
                        *ngIf="!field.type"
                      >
                        <ng-container
                          *ngIf="
                                                            field.subobject &&
                                                            !field.symbol
                                                        "
                        >
                          <ng-container
                            *ngIf="
                                                                field.date &&
                                                                !field.label
                                                            "
                          >
                            {{
                            record[
                              field.key
                              ][field.subkey]
                              | date
                            }}
                          </ng-container>
                          <ng-container
                            *ngIf="
                                                                !field.date &&
                                                                !field.label
                                                            "
                          >
                            {{
                            record[
                              field.key
                              ][field.subkey]
                            }}
                          </ng-container>

                          <ng-container
                            *ngIf="field.label"
                            class="label label-lg label-light-{{
                                                                field.getLabel(
                                                                    record[
                                                                        field
                                                                            .key
                                                                    ]
                                                                )
                                                            }} label-inline font-weight-bold"
                          >{{
                            record[
                              field.key
                              ]
                            }}</ng-container
                          >
                        </ng-container>

                        <ng-container
                          *ngIf="
                                                            !field.subobject &&
                                                            !field.subkey &&
                                                            !field.symbol
                                                        "
                        >
                          <ng-container
                            *ngIf="
                                                                field.date &&
                                                                !field.label
                                                            "
                          >
                            {{
                            record[
                              field.key
                              ] | date
                            }}
                          </ng-container>
                          <ng-container
                            *ngIf="
                                                                !field.date &&
                                                                !field.label &&
                                                                !field.valueBool &&
                                                                !field.edit &&
                                                                !field.mb
                                                            "
                          >
                            <div class="data_row">
                              {{
                              record[
                                field.key
                                ]
                              }}
                            </div>
                          </ng-container>
                          <ng-container
                            *ngIf="
                                                                !field.date &&
                                                                !field.label &&
                                                                !field.valueBool &&
                                                                !field.edit &&
                                                                field.mb
                                                            "
                          >
                            <div class="data_row">
                              {{
                              getMBData(record[
                                field.key
                                ])
                              }} MB
                            </div>
                          </ng-container>
                          <ng-container
                            *ngIf="
                                                                !field.date &&
                                                                !field.label &&
                                                                !field.valueBool &&
                                                                field.edit
                                                            "
                          >
                            <input
                              type="text"
                              [(ngModel)]="
                                                                    record[
                                                                        field
                                                                            .key
                                                                    ]
                                                                "
                              (change)="
                                                                    onChangeLabel(
                                                                        $event,
                                                                        record
                                                                    )
                                                                "
                              style="
                                                                    height: 100%;
                                                                    width: 100%;
                                                                    border: none;
                                                                    outline: none;
                                                                    background-color: transparent;
                                                                    min-width: 30vw;
                                                                    text-align: -webkit-center;
                                                                "
                              class="
                                                                    inputEtiqueta
                                                                "
                            />
                          </ng-container>
                          <ng-container
                            *ngIf="
                                                                !field.date &&
                                                                !field.label &&
                                                                field.valueBool
                                                            "
                          >
                            <ng-container
                              *ngIf="
                                                                    record[
                                                                        field
                                                                            .key
                                                                    ] === true
                                                                "
                            >
                              Si
                            </ng-container>
                            <ng-container
                              *ngIf="
                                                                    record[
                                                                        field
                                                                            .key
                                                                    ] === false
                                                                "
                            >
                              No
                            </ng-container>
                          </ng-container>

                          <ng-container
                            *ngIf="field.label"
                            class="label label-lg label-light-{{
                                                                field.getLabel(
                                                                    record[
                                                                        field
                                                                            .key
                                                                    ]
                                                                )
                                                            }} label-inline font-weight-bold"
                          >{{
                            record[
                              field.key
                              ]
                            }}</ng-container
                          >
                        </ng-container>

                        <ng-container
                          *ngIf="
                                                            !field.subobject &&
                                                            field.subkey &&
                                                            field.symbol
                                                        "
                        >
                          <ng-container
                            class="
                                                                d-flex
                                                                align-items-center
                                                            "
                          >
                            <ng-container
                              class="
                                                                    symbol
                                                                    symbol-40
                                                                    symbol-light-primary
                                                                    flex-shrink-0
                                                                "
                            >
                              <ng-container
                                class="
                                                                        symbol-label
                                                                        font-size-h4
                                                                        font-weight-bold
                                                                    "
                              >{{
                                record[
                                  field
                                    .key
                                  ][0]
                                }}</ng-container
                              >
                            </ng-container>
                            <ng-container
                              class="ml-4"
                            >
                              <ng-container
                                *ngIf="
                                                                        field.date
                                                                    "
                              >
                                {{
                                record[
                                  field
                                    .key
                                  ] | date
                                }}
                              </ng-container>
                              <ng-container
                                *ngIf="
                                                                        !field.date
                                                                    "
                              >
                                {{
                                record[
                                  field
                                    .key
                                  ]
                                }}
                              </ng-container>

                              <a>{{
                                record[
                                  field
                                    .subkey
                                  ]
                                }}</a>
                            </ng-container>
                          </ng-container>
                        </ng-container>

                        <ng-container
                          *ngIf="
                                                            !field.subobject &&
                                                            field.subkey &&
                                                            !field.symbol &&
                                                            !field.highlighted
                                                        "
                        >
                          <ng-container
                            class="
                                                                font-weight-bolder
                                                            "
                          >
                            {{
                            record[
                              field.key
                              ]
                            }}
                          </ng-container>
                          <ng-container>
                            {{
                            record[
                              field.subkey
                              ]
                            }}
                          </ng-container>
                        </ng-container>

                        <ng-container
                          *ngIf="
                                                            !field.subobject &&
                                                            field.subkey &&
                                                            !field.symbol &&
                                                            field.highlighted
                                                        "
                        >
                          <ng-container
                            class="
                                                                font-weight-bolder
                                                                text-primary
                                                            "
                          >
                            {{
                            record[
                              field.key
                              ]
                            }}
                          </ng-container>
                          <ng-container
                            class="
                                                                font-weight-bold
                                                            "
                          >
                            {{
                            record[
                              field.subkey
                              ]
                            }}
                          </ng-container>
                        </ng-container>
                      </ng-container>

                      <ng-container
                        *ngIf="field.type"
                      >
                        <ng-container
                          class="mr-2"
                          *ngIf="
                                                            field.type ===
                                                            'progressbar'
                                                        "
                        >
                          <ng-container
                            class="progress"
                          >
                            <ng-container
                              class="
                                                                    progress-bar
                                                                    progress-bar-striped
                                                                    progress-bar-animated
                                                                "
                              [ngClass]="{
                                                                    'bg-gray-400':
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ] === 0,
                                                                    'bg-danger':
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ] < 30,
                                                                    'bg-warning':
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ] >=
                                                                            30 &&
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ] < 50,
                                                                    'bg-primary':
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ] >=
                                                                            50 &&
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ] < 100,
                                                                    'bg-success':
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ] ===
                                                                        100,
                                                                    '':
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ] === 0
                                                                }"
                              role="progressbar"
                              aria-valuenow="10"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            >
                              {{
                              record[
                                field
                                  .key
                                ]
                                | number
                                : '1.2-2'
                              }}%
                            </ng-container>
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </td>

                    <td
                      *ngIf="model.enableActions"
                      data-field="Actions"
                      data-autohide-disabled="false"
                      aria-label="null"
                    >
                      <ng-container>
                        <ng-container
                          *ngTemplateOutlet="
                                                            actionsTemplate;
                                                            context: {
                                                                $implicit:
                                                                    record,
                                                                index: i
                                                            }
                                                        "
                        ></ng-container>
                      </ng-container>
                    </td>
                  </tr>
                  <div
                    class="row"
                    *ngIf="
                                                selectedRow === i && rowTemplate
                                            "
                  >
                    <div class="col-12">
                      <ng-container
                        *ngTemplateOutlet="
                                                        rowTemplate;
                                                        context: {
                                                            $implicit: record,
                                                            index: index
                                                        }
                                                    "
                      ></ng-container>
                    </div>
                  </div>
                </ng-container>
                </tbody>
              </table>
            </div>
            <!--begin::Table Empty-->
            <div
              class=""
              *ngIf="
                                !model?.records || model?.records?.length === 0 && tableType === 'normal'
                            "
            >
              <div class="row_void">
                <div class="text_table">
                  <h1 class="void_title">
                    No se han encontrado resultados
                  </h1>
                  <div class="void_desc">
                    Intenta añadir informacion o verifica
                    que tienes permisos asociados
                  </div>
                </div>
                <div class="img_table_container">
                  <img
                    src="assets/images/copy.svg"
                    class="img_table"
                  />
                </div>
              </div>
            </div>
            <!--end::Table Empty-->
            <!--begin::Table session-->
            <div
              class="grid table-container table-misim"
              *ngIf="
                                !model?.records || model?.records?.length === 0 && tableType === 'session'
                            "
            >
              <table>
                <thead>
                <tr class="header">

                  <th
                    *ngIf="!hideIndex"
                    data-field="RecordID"
                    data-sort="asc"
                  >
                    <span>#</span>
                  </th>

                  <th
                    *ngFor="let field of model.fields"
                    data-field="Actions"
                    data-autohide-disabled="false"
                  >
                                            <span>
                                                <ng-container
                                                  *ngIf="!field.filter"
                                                >
                                                    <span>
                                                        {{ field.title }}
                                                    </span>
                                                </ng-container>
                                            </span>
                  </th>
                  <th
                    *ngIf="model.enableActions"
                    data-field="Actions"
                    data-autohide-disabled="false"
                  >
                    <span>Acciones</span>
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <th [colSpan]="model.fields.length" class="not_msn">
                    <h1>
                      Esta SIM en particular no se registró en la red por un tiempo
                    </h1>
                  </th>
                </tr>
                </tbody>
              </table>
            </div>
            <!--end::Table session-->
            <!--begin::Table network-->
            <div
              class="grid table-container table-misim"
              *ngIf="
                                !model?.records || model?.records?.length === 0 && tableType === 'network'
                            "
            >
              <table>
                <thead>
                <tr class="header">

                  <th
                    *ngIf="!hideIndex"
                    data-field="RecordID"
                    data-sort="asc"
                  >
                    <span>#</span>
                  </th>

                  <th
                    *ngFor="let field of model.fields"
                    data-field="Actions"
                    data-autohide-disabled="false"
                  >
                                            <span>
                                                <ng-container
                                                  *ngIf="!field.filter"
                                                >
                                                    <span>
                                                        {{ field.title }}
                                                    </span>
                                                </ng-container>
                                            </span>
                  </th>
                  <th
                    *ngIf="model.enableActions"
                    data-field="Actions"
                    data-autohide-disabled="false"
                  >
                    <span>Acciones</span>
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <th [colSpan]="model.fields.length" class="not_msn">
                    <h1>
                      No hay una sesión de datos activa en esta tarjeta SIM en particular
                    </h1>
                  </th>
                </tr>
                </tbody>
              </table>
            </div>
            <!--end::Table network    -->
          </div>
        </ng-container>
      </div>
    </div>
  </section>

  <!--end: Datatable-->
</ng-template>

<ng-template #datatableTemplateModal>
  <section class="content-modal">
    <div class="container-fluid">
      <div class="main-card mb-3 mt-3">
        <div class="card-header-table">
          <ng-container *ngIf="model?.records?.length > 0">
            <app-pagination
              [model]="model"
              [pagination]="model.pagination"
              [title]="model.title"
              (clickedPage)="clickedPage($event)"
              (clickedPageSize)="clickedPageSize($event)"
              (getTotalRecords)="getTotalRecords($event)"
              (changeSearch)="changeSearch($event)"
            ></app-pagination>
          </ng-container>
        </div>
        <ng-container>
          <div>
            <div
              id="kt_datatable"
              class="grid table-container"
              style="
                                overflow-x: auto;
                                width: 100%;
                                height: max-content;
                            "
            >
              <table>
                <thead>
                <tr class="header">
                  <th *ngIf="!hideIndex">
                    <span></span>
                  </th>

                  <th
                    *ngIf="!hideIndex"
                    data-field="RecordID"
                    data-sort="asc"
                  >
                    <span>#</span>
                  </th>

                  <th
                    *ngFor="let field of model.fields"
                    data-field="Actions"
                    data-autohide-disabled="false"
                  >
                                            <span>
                                                <ng-container
                                                  *ngIf="!field.filter"
                                                >
                                                    <span>
                                                        {{ field.title }}
                                                    </span>
                                                </ng-container>
                                            </span>
                  </th>
                  <th
                    *ngIf="model.enableActions"
                    data-field="Actions"
                    data-autohide-disabled="false"
                  >
                    <span>Acciones</span>
                  </th>
                </tr>
                </thead>
                <tbody *ngIf="model?.records?.length > 0">
                <ng-container
                  *ngFor="
                                            let record of model.records;
                                            let i = index
                                        "
                >
                  <tr data-row="10" class="tr-table">
                    <td
                      *ngIf="!hideIndex"
                      (click)="clickedRow(i)"
                    >
                      <a>
                        <i
                          class="fa fa-caret-{{
                                                            selectedRow == i
                                                                ? 'down'
                                                                : 'right'
                                                        }}"
                        ></i>
                      </a>
                    </td>

                    <td
                      *ngIf="!hideIndex"
                      (click)="clickedRow(i)"
                      data-field="RecordID"
                      aria-label="11"
                    >
                      <ng-container
                      >
                        <ng-container>{{
                          i +
                          1 +
                          (model.pagination
                              .currentPage -
                            1) *
                          model.pagination
                            .pageSize
                          }}</ng-container>
                      </ng-container
                      >
                    </td>

                    <td
                      (click)="clickedRow(i)"
                      *ngFor="
                                                    let field of model.fields
                                                "
                      aria-label="Littel and Sons"
                    >
                      <ng-container
                        *ngIf="!field.type"
                      >
                        <ng-container
                          *ngIf="
                                                            field.subobject &&
                                                            !field.symbol
                                                        "
                        >
                          <ng-container
                            *ngIf="
                                                                field.date &&
                                                                !field.label
                                                            "
                          >
                            {{
                            record[
                              field.key
                              ][field.subkey]
                              | date
                            }}
                          </ng-container>
                          <ng-container
                            *ngIf="
                                                                !field.date &&
                                                                !field.label
                                                            "
                          >
                            {{
                            record[
                              field.key
                              ][field.subkey]
                            }}
                          </ng-container>

                          <ng-container
                            *ngIf="field.label"
                            class="label label-lg label-light-{{
                                                                field.getLabel(
                                                                    record[
                                                                        field
                                                                            .key
                                                                    ]
                                                                )
                                                            }} label-inline font-weight-bold"
                          >{{
                            record[
                              field.key
                              ]
                            }}</ng-container
                          >
                        </ng-container>

                        <ng-container
                          *ngIf="
                                                            !field.subobject &&
                                                            !field.subkey &&
                                                            !field.symbol
                                                        "
                        >
                          <ng-container
                            *ngIf="
                                                                field.date &&
                                                                !field.label
                                                            "
                          >
                            {{
                            record[
                              field.key
                              ] | date
                            }}
                          </ng-container>
                          <ng-container
                            *ngIf="
                                                                !field.date &&
                                                                !field.label &&
                                                                !field.valueBool
                                                            "
                          >
                            {{
                            record[
                              field.key
                              ]
                            }}
                          </ng-container>
                          <ng-container
                            *ngIf="
                                                                !field.date &&
                                                                !field.label &&
                                                                field.valueBool
                                                            "
                          >
                            <ng-container
                              *ngIf="
                                                                    record[
                                                                        field
                                                                            .key
                                                                    ] === true
                                                                "
                            >
                              Si
                            </ng-container>
                            <ng-container
                              *ngIf="
                                                                    record[
                                                                        field
                                                                            .key
                                                                    ] === false
                                                                "
                            >
                              No
                            </ng-container>
                          </ng-container>

                          <ng-container
                            *ngIf="field.label"
                            class="label label-lg label-light-{{
                                                                field.getLabel(
                                                                    record[
                                                                        field
                                                                            .key
                                                                    ]
                                                                )
                                                            }} label-inline font-weight-bold"
                          >{{
                            record[
                              field.key
                              ]
                            }}</ng-container
                          >
                        </ng-container>

                        <ng-container
                          *ngIf="
                                                            !field.subobject &&
                                                            field.subkey &&
                                                            field.symbol
                                                        "
                        >
                          <ng-container
                            class="
                                                                d-flex
                                                                align-items-center
                                                            "
                          >
                            <ng-container
                              class="
                                                                    symbol
                                                                    symbol-40
                                                                    symbol-light-primary
                                                                    flex-shrink-0
                                                                "
                            >
                              <ng-container
                                class="
                                                                        symbol-label
                                                                        font-size-h4
                                                                        font-weight-bold
                                                                    "
                              >{{
                                record[
                                  field
                                    .key
                                  ][0]
                                }}</ng-container
                              >
                            </ng-container>
                            <ng-container
                              class="ml-4"
                            >
                              <ng-container
                                *ngIf="
                                                                        field.date
                                                                    "
                              >
                                {{
                                record[
                                  field
                                    .key
                                  ] | date
                                }}
                              </ng-container>
                              <ng-container
                                *ngIf="
                                                                        !field.date
                                                                    "
                              >
                                {{
                                record[
                                  field
                                    .key
                                  ]
                                }}
                              </ng-container>

                              <a>{{
                                record[
                                  field
                                    .subkey
                                  ]
                                }}</a>
                            </ng-container>
                          </ng-container>
                        </ng-container>

                        <ng-container
                          *ngIf="
                                                            !field.subobject &&
                                                            field.subkey &&
                                                            !field.symbol &&
                                                            !field.highlighted
                                                        "
                        >
                          <ng-container
                            class="
                                                                font-weight-bolder
                                                            "
                          >
                            {{
                            record[
                              field.key
                              ]
                            }}
                          </ng-container>
                          <ng-container>
                            {{
                            record[
                              field.subkey
                              ]
                            }}
                          </ng-container>
                        </ng-container>

                        <ng-container
                          *ngIf="
                                                            !field.subobject &&
                                                            field.subkey &&
                                                            !field.symbol &&
                                                            field.highlighted
                                                        "
                        >
                          <ng-container
                            class="
                                                                font-weight-bolder
                                                                text-primary
                                                            "
                          >
                            {{
                            record[
                              field.key
                              ]
                            }}
                          </ng-container>
                          <ng-container
                            class="
                                                                font-weight-bold
                                                            "
                          >
                            {{
                            record[
                              field.subkey
                              ]
                            }}
                          </ng-container>
                        </ng-container>
                      </ng-container>

                      <ng-container
                        *ngIf="field.type"
                      >
                        <ng-container
                          class="mr-2"
                          *ngIf="
                                                            field.type ===
                                                            'progressbar'
                                                        "
                        >
                          <ng-container
                            class="progress"
                          >
                            <ng-container
                              class="
                                                                    progress-bar
                                                                    progress-bar-striped
                                                                    progress-bar-animated
                                                                "
                              [ngClass]="{
                                                                    'bg-gray-400':
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ] === 0,
                                                                    'bg-danger':
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ] < 30,
                                                                    'bg-warning':
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ] >=
                                                                            30 &&
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ] < 50,
                                                                    'bg-primary':
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ] >=
                                                                            50 &&
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ] < 100,
                                                                    'bg-success':
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ] ===
                                                                        100,
                                                                    '':
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ] === 0
                                                                }"
                              role="progressbar"
                              aria-valuenow="10"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            >
                              {{
                              record[
                                field
                                  .key
                                ]
                                | number
                                : '1.2-2'
                              }}%
                            </ng-container>
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </td>

                    <td
                      *ngIf="model.enableActions"
                      data-field="Actions"
                      data-autohide-disabled="false"
                      aria-label="null"
                    >
                      <ng-container>
                        <ng-container
                          *ngTemplateOutlet="
                                                            actionsTemplate;
                                                            context: {
                                                                $implicit:
                                                                    record,
                                                                index: i
                                                            }
                                                        "
                        ></ng-container>
                      </ng-container>
                    </td>
                  </tr>
                  <div
                    class="row"
                    *ngIf="
                                                selectedRow === i && rowTemplate
                                            "
                  >
                    <div class="col-12">
                      <ng-container
                        *ngTemplateOutlet="
                                                        rowTemplate;
                                                        context: {
                                                            $implicit: record,
                                                            index: index
                                                        }
                                                    "
                      ></ng-container>
                    </div>
                  </div>
                </ng-container>
                </tbody>
              </table>
            </div>
            <!--begin::Table Empty-->

            <!--end::Table Empty-->
          </div>
        </ng-container>
      </div>
    </div>
  </section>

  <!--end: Datatable-->
</ng-template>

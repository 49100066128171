<app-header (toggleMenuSidebar)="toggleMenuSidebar()"></app-header>

<app-menu-sidebar class="main-sidebar elevation-4"></app-menu-sidebar>

<div class="content-wrapper">
    <router-outlet></router-outlet>
</div>

<ng-template [ngIf]="show_notifications === true">
    <app-notifications></app-notifications>
</ng-template>

<app-footer class="main-footer"></app-footer>
<aside class="control-sidebar"></aside>
<div id="sidebar-overlay" (click)="toggleMenuSidebar()"></div>

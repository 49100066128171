<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h2 class="title-main m-0">Simcards con plan</h2>
      </div>
      <!-- /.col -->
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item">
            <a [routerLink]="['/']">Home</a>
          </li>
          <li class="breadcrumb-item active">Simcards con plan </li>
        </ol>
      </div>
      <!-- /.col -->
    </div>
    <!-- /.row -->
  </div>
</div>

<ng-template #actionsTemplate let-index="index" let-$implicit="$implicit">
  <div class="row m-0 p-0 w-100">
    <div class="acionts-content">

      <div
        class="icon-td"
        (click)="openModalSendMessage($implicit)"
        matTooltip="Mensaje"
      >
        <svg
          version="1.1"
          id="listos"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 100 100"
          style="enable-background: new 0 0 100 100"
          xml:space="preserve"
          class="table-icon"
        >
                    <path
                      fill="current"
                      d="M60,72v18c0,0.8-0.1,1.5-0.3,2.3c-1,4.4-5,7.8-9.7,7.8H10c-4.8,0-8.7-3.3-9.7-7.8C0.1,91.5,0,90.8,0,90V10
           C0,4.5,4.5,0,10,0h40c4.8,0,8.9,3.4,9.8,8H10c-1.1,0-2,0.9-2,2v78h44V72H60z M80,36H40v8h40V36z M92,24H28v28v8.7l2.3-2.3l2.3-2.3
           H36h56V24 M100,16v48H36L20,80V52V16H100L100,16z"
                    />
                </svg>
      </div>

    </div>
  </div>
</ng-template>


<ng-template [ngIf]="show_modal === true && show_view_records === true">
  <app-card-modal [titleHeader]="'HISTORIAL'" [color]="'#01BEBD'">
    <app-records
      style="width: 100%"
      [card_selected]="card_selected"
    ></app-records>
  </app-card-modal>
</ng-template>
<ng-template [ngIf]="show_modal && show_send_message">
  <app-card-modal [titleHeader]="'NUEVO MENSAJE'" [color]="'#C6007E'">
    <form
      class="message-modal column"
      [formGroup]="messageForm"
      (ngSubmit)="sendMessageTruphone()"
    >
      <div class="body column">
        <div class="content-type column">
          <div class="title-type row">
            <div>Emisor</div>
            <div class="span">
              {{ card_selected.primaryMsisdn }}
            </div>
          </div>
          <div class="body-type row">
            <div class="item-type row">
                            <textarea
                              name="body"
                              id=""
                              formControlName="body"
                              class="text-body"
                            ></textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="button-content row">
          <button class="btn btn4" (click)="closeModalShareBalance()">
            Cancelar
          </button>
          <button class="btn btn3" [type]="'submit'">Enviar</button>
        </div>
      </div>
    </form>
  </app-card-modal>
</ng-template>
<ng-container *ngIf="loading">
  <app-modal-loading [loading]="true"></app-modal-loading>
</ng-container>
<ng-container *ngIf="created">
  <app-modal-loading [success]="true" [title]="title"></app-modal-loading>
</ng-container>
<ng-container *ngIf="error">
  <app-modal-loading [error]="true" [title]="title"></app-modal-loading>
</ng-container>
<ng-container *ngIf="!loading2">
  <app-table
    [model]="cardsModel"
    [removeCard]="true"
    (paginatorEvent)="paginatorEvent($event)"
    (pageSizeEvent)="pageSizeEvent($event)"
    [actionsTemplate]="actionsTemplate"
    [hideIndex]="true"
    (searchEvent)="searchP($event)"
    (getRecords)="getTotalCards($event)"
    (onHandleLabel)="onHandleLabel($event)"
    (filterEvent)="changeFilter($event)"
  ></app-table>
</ng-container>
<ng-container *ngIf="loading2">
  <div class="loading-content">
    <mat-spinner mode="indeterminate"></mat-spinner>
  </div>
</ng-container>

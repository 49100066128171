<div class="Footer">
    <div class="float-right d-none d-sm-block">
        <b>Version</b> {{ appVersion }}
    </div>
    <strong>
        <span>Copyright &copy; {{ currentYear }}</span>
        <a
            href="www.monitoringinnovation.com"
            target="_blank"
            rel="noopener noreferrer"
            style="margin: 0"
        >
            www.monitoringinnovation.com</a
        >
        <span>.</span>
    </strong>
    <span> All rights reserved.</span>
</div>

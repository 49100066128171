<div class="cardDash" [ngStyle]="{'background-color': color}">
    <div class="cardDash-content">
        <div class="cardDash-number" [ngStyle]="{color: color}">
            {{ number }} <ng-container *ngIf="number >= 0 && showDataMb">MB</ng-container>
        </div>
        <div class="cardDash-title">{{ title }}</div>
    </div>
    <a class=" row cardDash-footer" [routerLink]="['/', link]">
        <div class="cardDash-footer-text">Más información</div>
        <div class="icon-td">
            <svg
                version="1.1"
                id="listos"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 100 100"
                style="enable-background: new 0 0 100 100"
                xml:space="preserve"
                class="table-icon"
            >
                <path
                    fill="#ffffff"
                    d="M49.8,0l38.9,38.7L100,50L88.7,61.3L49.8,100L38.5,88.8L69.4,58H0V42h69.4L38.5,11.2L49.8,0z"
                />
            </svg>
        </div>
    </a>
</div>

<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h2 class="title-main m-0">SIM Doctor</h2>
      </div>
      <!-- /.col -->
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item">
            <a class="home" [routerLink]="['/']">Home</a>
          </li>
          <li class="breadcrumb-item active">Generar Informe</li>
        </ol>
      </div>
      <div class="page-subheading">
        <div class="subtitle">
          En esta sección puede generar un reporte, configure los
          parametros requeridos a continuación.
        </div>
      </div>
      <!-- /.col -->
    </div>
    <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</div>

<div class="content-general column">
  <div class="content-Segundario column">
    <div class="header">
      <div class="title">HISTORIAL</div>
    </div>
    <form class="form column" [formGroup]="recordsForm" (ngSubmit)="getSimStatus()" *ngIf="showForm">
      <div class="flex-direction">
        <ng-container *ngIf="!loadingSimcards" class="col-12 column content-card">
          <div class="content-type column col-12">
            <div class="add-input column">
              <label>Selecione SIM</label>
              <div>
                <mat-select type="text" class="i-select i-select-material" placeholder="Simcard" formControlName="sim"
                  (selectionChange)="changeSimSelected($event.value)">
                  <input (keyup)="searchSim($event.target.value)" placeholder="Buscar sim.." class="search-select" />
                  <mat-option *ngFor="let card of SelectSimcards" [value]="card.onum">{{ card.onum }}</mat-option>
                </mat-select>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="footer">

        <div class="button-content row">
          <button class="btn2" [type]="'submit'">
            Examinar
          </button>
        </div>
      </div>
    </form>
    <ng-container *ngIf="showTableHistory">
      <div class="history_table">
        <app-table [model]="dataModelSimDoctor" [removeCard]="true" [hideIndex]="true" [hideHeader]="true"
          [showReturnReport]="true" [showTableName]="true" [tableName]="name_gbalance"
          (clickedReturn)="clickReturn($event)"></app-table>
      </div>
    </ng-container>
    <ng-container *ngIf="showTableHistory">
      <div class="history_table">
        <app-table [model]="dataModelCheckDataSession" [removeCard]="true" [hideIndex]="true" [hideHeader]="true"
          [showReturnReport]="true" [showTableName]="true" [tableType]="'session'" [tableName]="name_checkdatasession"
          (clickedReturn)="clickReturn($event)"></app-table>
      </div>
    </ng-container>
    <ng-container *ngIf="showTableHistory">
      <div class="history_table">
        <app-table [model]="dataModelGetLuActivity" [removeCard]="true" [hideIndex]="true" [hideHeader]="true"
          [showReturnReport]="true" [showTableName]="true" [tableName]="name_getlvactivity"
          (clickedReturn)="clickReturn($event)"></app-table>
      </div>
    </ng-container>
    <ng-container *ngIf="showTableHistory">
      <div class="history_table">
        <app-table [model]="dataModelGetLu" [removeCard]="true" [hideIndex]="true" [hideHeader]="true"
          [showReturnReport]="true" [showTableName]="true" [tableType]="'network'" [tableName]="name_getlu"
          (clickedReturn)="clickReturn($event)"></app-table>
      </div>
    </ng-container>
    <ng-container *ngIf="showTableHistory">
      <div class="row">
        <div class="col-12">
          <div class="btn_history_table">
            <button class="btn-simdoctor" [ngClass]="rotate_icon_reset ? 'background-rotate' : ''" (click)="simReset()">
              <div class="icon-td  content-icon-reset" [ngClass]="rotate_icon_reset ? 'reset-icon-rotate' : ''"
                matTooltip="Reset">
                <svg viewBox="0 0 29 30" [ngClass]="rotate_icon_reset ? 'image-rotate' : ''" fill="current"
                  xmlns="http://www.w3.org/2000/svg" class="table-icon">
                  <g filter="url(#filter0_d_1489_7700)">
                    <path
                      d="M26.35 16.5C24.675 21.45 20 25 14.5 25C11.35 25 8.475 23.825 6.25 21.9C5.25 21 4.375 19.95 3.7 18.8L2 20.5V12.5H10L5.925 16.575C6.525 17.8 7.35 18.9 8.4 19.775C10.05 21.15 12.175 22 14.5 22C18.3 22 21.6 19.75 23.125 16.5H26.35Z"
                      fill="current" />
                    <path
                      d="M27 4.5V12.5H19L23.075 8.425C22.475 7.2 21.65 6.1 20.6 5.225C18.95 3.85 16.825 3 14.5 3C10.7 3 7.4 5.25 5.875 8.5H2.65C4.325 3.55 9 0 14.5 0C17.65 0 20.525 1.175 22.75 3.1C23.75 3.975 24.625 5.025 25.3 6.2L27 4.5Z"
                      fill="current" />
                  </g>
                  <defs>
                    <filter id="filter0_d_1489_7700" x="0" y="0" filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha" />
                      <feOffset dy="3" />
                      <feGaussianBlur stdDeviation="1" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1489_7700" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1489_7700" result="shape" />
                    </filter>
                  </defs>
                </svg>
              </div>
              <p class="text-reset-btn" [ngClass]="rotate_icon_reset ? 'text-rotate' : ''">
                Reset
              </p>
            </button>
            <button class="btn-simdoctor" (click)="checkSimStatus()">
              <div class="icon-td" matTooltip="Reset">
                <svg version="1.1" id="listos" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100"
                  style="enable-background: new 0 0 100 100" xml:space="preserve" class="table-icon">
                  <path fill="current"
                    d="M60,88v2c0,5.5-4.5,10-10,10H10c-5.5,0-10-4.5-10-10V10C0,4.5,4.5,0,10,0h40c4.8,0,8.9,3.4,9.8,8H10
                        c-1.1,0-2,0.9-2,2v78H60z M36,15v10c30.3,0,55,24.7,55,55h10C101,44.2,71.8,15,36,15z M36,36.6v10c18.4,0,33.4,15,33.4,33.4h10
                        C79.4,56.1,59.9,36.6,36,36.6z M36,58.2v10c6.5,0,11.8,5.3,11.8,11.8h10C57.8,68,48,58.2,36,58.2z" />
                </svg>
              </div>
              Ping
            </button>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>


<ng-container *ngIf="loading">
  <app-modal-loading [loading]="true"></app-modal-loading>
</ng-container>
<ng-container *ngIf="error">
  <app-modal-loading [error]="true" title="title"></app-modal-loading>
</ng-container>
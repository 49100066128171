import {Component, Input, OnInit} from '@angular/core';
import {SwitchService} from '@services/switch.service';

@Component({
    selector: 'app-card-modal',
    templateUrl: './card-modal.component.html',
    styleUrls: ['./card-modal.component.scss']
})
export class CardModalComponent implements OnInit {
    @Input()
    titleHeader: string;

    @Input()
    color: string = '#01BEBD';

    constructor(private modalServicer: SwitchService) {}

    ngOnInit(): void {}

    close() {
        // this.callbackFunction();
        this.modalServicer.$modal.emit(false);
    }

    onDestroy() {}
}

import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-card-dash',
    templateUrl: './card-dash.component.html',
    styleUrls: ['./card-dash.component.scss']
})
export class CardDashComponent implements OnInit {
    @Input()
    ngStyle: {[klass: string]: any};

    @Input()
    color: string = '#01BEBD';

    @Input()
    title: string = 'Nombre del objeto';

    @Input()
    link: string = '';

    @Input()
    number: number = 0;

    @Input()
    showDataMb: boolean = false;

    constructor() {}

    ngOnInit(): void {}
}

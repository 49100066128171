<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h2 class="title-main m-0">Dashboard</h2>
        <button (click)="toggleTypeService()" [ngClass]="{'active': isClasicTypeDash}">
          {{ isClasicTypeDash ? 'Tarjetas con plan' : 'Tarjetas clasicas' }}
        </button>
      </div>
      <!-- /.col -->
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item">
            <a [routerLink]="['/']">Home</a>
          </li>
          <li class="breadcrumb-item active">Dashboard</li>
        </ol>
      </div>
      <div class="loading-content" *ngIf="loading">
        <mat-spinner mode="indeterminate"></mat-spinner>
      </div>
      <!-- /.col -->
    </div>
    <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content">
  <div class="container-fluid">
    <!-- Small boxes (Stat box) -->
    <ng-container *ngIf="!loading">
      <div class="dashboard-content" *ngIf="!loading
">
        <app-card-dash
          [title]="'Tarjetas SIM'"
          [number]=cardsCount
          [color]="'#01BEBD'"
          [link]="'sim'"
        ></app-card-dash>
        <!-- small box -->
        <app-card-dash
          *ngIf="!gettingMaxBalance && isClasicTypeDash"
          [title]="'Saldo actual'"
          [number]= account.data_balance
          [color]="'#00249C'"
          [link]="'account'"
          [showDataMb]="true"
        ></app-card-dash>
        <ng-container
          *ngIf="gettingMaxBalance"
        >
          <div class="loading-content-balance">
            <mat-spinner [diameter]="30" mode="indeterminate"></mat-spinner>
          </div>
        </ng-container>

        <app-card-dash
          [title]="'Mensajes enviados'"
          [number]=messages_count
          [color]="'#C6007E'"
          [link]="'messages'"
        ></app-card-dash>

        <app-card-dash
          *ngIf = "isClasicTypeDash"
          [title]="'Gasto de datos en el mes'"
          [number]= balance_month
          [color]="'#00249C'"
          [link]="'account'"
          [showDataMb]="true"
        ></app-card-dash>
      </div>
    </ng-container>
    <!-- /.row -->
  </div>
  <!-- Main row -->
</section>
<!-- /.content -->

<div
    class="Notification column"
    [ngClass]="active ? 'active' : ''"
    [ngClass]="desactivate ? 'desactivate' : ''"
>
    <div *ngFor="let notify of notifications; index as i; first as isFirst">
        <div class="row notification-item">
            <div class="column notification-item-description">
                <div class="blue2 Notification-title">
                    {{ notify.title }}
                </div>
                <div class="red2 Notification-text">
                    {{ notify.create_date }}
                </div>
                <div class="gray2 Notification-text">
                    {{ notify.description }}
                </div>
            </div>
        </div>
    </div>
</div>

import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SwitchService {
    constructor() {}

    $modal = new EventEmitter<any>();
    $modalExport = new EventEmitter<any>();
    $modalLoading = new EventEmitter<any>();
    $created = new EventEmitter<any>();
    $error = new EventEmitter<any>();
    $notifications = new EventEmitter<any>();
    $card_selected = new EventEmitter<any>();
}

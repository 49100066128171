import {AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {Card} from "@/models/card";
import {TableViewModel} from "@components/models/table";
import {ApiService} from "@services/api.service";
import {AppService} from "@services/app.service";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";

@Component({
  selector: 'app-manage-fund',
  templateUrl: './manage-fund.component.html',
  styleUrls: ['./manage-fund.component.scss']
})
export class ManageFundComponent implements AfterViewInit {

  // sim: string;
  // icc: string;
  // balance: string;
  // curr: string;
  // daily_count: number;
  displayedColumns: string[] = [
    'sim',
    'icc',
    'daily_count',
    'balance',
    'curr'
  ];
  cards: Card[] = null;
  cards_count: number = null;
  cardsModel: TableViewModel = {
    title: 'Fondos Tarjetas SIM',
    description: '',
    filter: {
      page: 1,
      pageSize: 5
    },
    fields: [
      {title: 'Telefono', key: 'sim', filter: false, data: []},
      {
        title: 'Contador diario',
        key: 'daily_count',
        filter: false,
        data: []
      },
      {title: 'Balance', key: 'balance', filter: false, data: []}
    ],
    records: [],
    showFilters: true,
    enableActions: true
  };

  dataSource: any = null;
  currentPage: number = 1;
  pageSize: number = 5;

  constructor(
    private apiService: ApiService,
    private appService: AppService,
    private cd: ChangeDetectorRef
  ) {}

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  ngAfterViewInit() {
    this.apiService
      .getCardsInfo(this.currentPage, this.pageSize)
      .then((cards) => {
        if (cards.result) {
          this.cards = cards.result;
          this.cards_count = cards.total_cards_count;
          this.cardsModel.records = this.cards;
          this.cardsModel.pagination = {
            currentPage: this.currentPage,
            pageSize: this.pageSize,
            pageCount: cards.count,
            rowCount: this.cards_count
          };
          this.cd.detectChanges();
        } else {
          this.logout();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  logout() {
    this.appService.logout();
  }
  onRemove(index: number) {
    console.log('remove event ! ');
  }

  paginatorEvent(currentPage: number) {
    this.apiService
      .getCardsInfo(currentPage, this.pageSize)
      .then((cards) => {
        if (cards.result) {
          this.cards = cards.result;
          this.cards_count = cards.total_cards_count;
          this.cardsModel.records = this.cards;
          this.cardsModel.pagination = {
            currentPage: this.currentPage,
            pageSize: this.pageSize,
            pageCount: cards.count,
            rowCount: this.cards_count
          };
          this.cd.detectChanges();
        } else {
          this.logout();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  pageSizeEvent(pageSize: number) {
    this.pageSize = pageSize;
    this.paginatorEvent(this.currentPage);
  }

}

export class Pagination
{
    constructor(){

    }
    currentPage: number;
    pageSize: number;
    pageCount?: number;
    rowCount?: number;
}

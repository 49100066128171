import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {
  faTh,
  faCheck,
  faTrash,
  faAngleDown,
  faAngleUp
} from '@fortawesome/free-solid-svg-icons';
import {AppService} from '@services/app.service';
import {ApiService} from '@services/api.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  heading = 'MI Cuenta';
  subheading = 'Bienvenido al panel de control MI SIM.';
  icon = 'pe-7s-id icon-gradient bg-happy-fisher';

  faTh = faTh;
  faCheck = faCheck;
  faTrash = faTrash;
  faAngleDown = faAngleDown;
  faAngleUp = faAngleUp;

  balance_month: number = 0;
  messages_count: number = null;
  cards_count: number = null;
  gettingMaxBalance: boolean = false;

  user: any = null;
  account: any = null;
  cardsCount: number = 0;

  loading: boolean = true;
  isClasicTypeDash: boolean = true;


  constructor(
    private appService: AppService,
    private apiService: ApiService,
    private cd: ChangeDetectorRef
  ) {
  }

  async ngOnInit() {
    this.loading = true;
    this.appService
      .getSessionInfo()
      .then((user) => (user ? (this.user = user) : this.logout()));
    this.apiService.getCardsInfo(1, 1).then((messages) => {
      this.cardsCount = messages.total_cards_count;
      this.cd.detectChanges();
    });
    this.apiService.getMessageInfo(1, 1).then((messages) => {
      this.messages_count = messages.count;
    });
    await this.appService.getAccountInfo().then((account) => {
      if (account) {
        (this.account = account);
        this.account.data_balance = Number.parseFloat(account.data_balance);
      } else {
        this.logout();
      }
    });
    this.getBalanceMonth();
    await this.changeMaxBalance();
    this.loading = false;

    //Get truphone cards
    this.apiService.getTruphoneCardsInfo()
  }

  async changeMaxBalance() {
    this.account.data_balance = null;
    this.gettingMaxBalance = true;
    await this.apiService.getBalanceTransfer('group', this.account.group_id).then((res) => {
      this.account.data_balance = res.data_balance;
      this.gettingMaxBalance = false;
    });
  }

  getBalanceMonth() {
    this.apiService.getBalanceMonth().then((balance) => {
      if (balance.result) {
        this.balance_month = balance.result;
      }
    });
  }

  ngOnDestroy() {
    console.log('Componente dashboard destruido');
  }

  logout() {
    this.appService.logout();
  }

  toggleTypeService() {
    this.isClasicTypeDash = !this.isClasicTypeDash;
    if (this.isClasicTypeDash) {
      this.apiService.getCardsInfo(1, 1).then((messages) => {
        this.cardsCount = messages.total_cards_count;
        this.cd.detectChanges();
      });
      this.apiService.getMessageInfo(1, 1).then((messages) => {
        this.messages_count = messages.count;
      });
    } else {
      this.apiService.getTruphoneCardsInfo().then((cards)=>{
      this.cardsCount = cards.total_cards_count;
    });
    this.apiService.getSendSms().then((messages) => {
      this.messages_count = messages.total_send_sms_count;
    });
    }
  }
}

import {Component, OnInit} from '@angular/core';
import {AppService} from '@services/app.service';
import {SidebarService} from '@services/sidebar.service';

@Component({
    selector: 'app-menu-sidebar',
    templateUrl: './menu-sidebar.component.html',
    styleUrls: ['./menu-sidebar.component.scss']
})
export class MenuSidebarComponent implements OnInit {
    public user;
    show_sub_history: boolean;

    constructor(
        public appService: AppService,
        private sidebarService: SidebarService
    ) {}

    ngOnInit() {
        this.user = this.appService.user;
        this.sidebarService.$sub_menu_history.subscribe((value) => {
            this.show_sub_history = value;
        });
    }

    toggleMenuHistory() {
        this.sidebarService.$sub_menu_history.emit(!this.show_sub_history);
    }
}

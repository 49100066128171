import {
    Component,
    Input,
    ChangeDetectorRef,
    SimpleChanges,
    OnChanges,
    Output,
    EventEmitter,
    OnInit
} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {TableViewModel} from '@components/models/table';
import {ExcelService} from '@services/excel.service';
import {SwitchService} from '@services/switch.service';
import Swal from 'sweetalert2';
import {Pagination} from '../models/Pagination';
import {PaginationService} from './pagination.service';

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnChanges, OnInit {
    @Input()
    public pagination: Pagination;

    pages: number[] = [];
    count: number = 0;
    currentPage: number = 1;
    exportModal: boolean = false;
    showModalExpor: boolean;
    @Input()
    showPageSize?: boolean = false;
    @Input()
    showReturnReport?: boolean = false;
    @Input()
    pageSize: number = 5;
    @Input()
    title: string = '';
    @Input()
    model: TableViewModel;

    @Output() clickedPage = new EventEmitter<number>();
    @Output() clickedPageSize = new EventEmitter<number>();
    @Output() clickedReturn = new EventEmitter<any>();
    @Output() changeSearch = new EventEmitter<string>();
    @Output() getTotalRecords = new EventEmitter<any>();

    searchForm: FormGroup;

    constructor(
        public ref: ChangeDetectorRef,
        private cd: ChangeDetectorRef,
        private excelService: ExcelService,
        private modalService: SwitchService
    ) {}

    get totalPages() {
        if (!this.pagination) {
            return 0;
        }
        if (this.pagination.rowCount % this.pagination.pageSize == 0) {
            return Math.trunc(
                this.pagination.rowCount / this.pagination.pageSize
            );
        } else {
            return (
                Math.trunc(
                    this.pagination.rowCount / this.pagination.pageSize
                ) + 1
            );
        }
    }

    ngOnInit() {
        this.searchForm = new FormGroup({
            search: new FormControl(null)
        });
        this.modalService.$modalExport.subscribe((value) => {
            this.showModalExpor = value;
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        console.log(changes);
    }

    onPage(page: number) {
        if (page == this.currentPage) return;
        this.count = page - 1;
        this.currentPage = page;
        this.onClick(this.currentPage);
    }

    next() {
        if (this.count + 1 == this.totalPages) return;
        this.count++;
        this.currentPage++;
        this.onClick(this.currentPage);
    }

    initPage() {
        if (this.count == 0) return;
        this.count = 0;
        this.currentPage = 1;
        this.onClick(this.currentPage);
    }

    endPage() {
        if (this.count + 1 == this.totalPages) return;
        this.currentPage = this.totalPages;
        this.count = this.currentPage - 1;
        this.onClick(this.currentPage);
    }

    previous() {
        if (this.count == 0) return;
        this.count--;
        this.currentPage--;
        this.onClick(this.currentPage);
    }

    search(searchby: string) {
        this.changeSearch.emit(searchby);
    }

    onClick(pageNumber: number) {
        this.clickedPage.emit(pageNumber);
        this.cd.detectChanges();
    }

    onClickReturn(event) {
      this.clickedReturn.emit(event);
    }

    changePageSize(pageSize: number) {
        this.clickedPageSize.emit(pageSize);
    }

    exportExcel() {
        var data = {
            title: this.model.title,
            header: [],
            data: []
        };
        for (var i = 0; this.model.fields.length > i; i++) {
            var obj = this.model.fields;
            data.header.push(obj[i].title);
        }
        for (var i = 0; this.model.records.length > i; i++) {
            var row = [];
            var record = this.model.records[i];
            for (var i2 = 0; this.model.fields.length > i2; i2++) {
                var field = this.model.fields[i2];
                row.push(record[field.key]);
            }
            data.data.push(row);
        }
        this.excelService.generateExcel(data);
    }

    showModalExport() {
        this.modalService.$modalExport.emit(true);
    }

    async exportAllExcel() {
        if (this.model) {
            var data = {
                title: this.model.title,
                header: [],
                keys: []
            };
            for await (let obj of this.model.fields) {
                data.header.push(obj.title);
                data.keys.push(obj.key);
            }
            this.getTotalRecords.emit(data);
        }
    }
}

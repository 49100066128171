<form
    class="records-modal column"
    [formGroup]="recordsForm"
    (ngSubmit)="getRecords()"
>
    <div class="header"></div>
    <div class="body column">
        <div class="content-type column">
            <div class="title-type">Fecha de historial</div>
            <div class="body-type row">
                <div class="item-type row">
                    <input
                        type="date"
                        class="input-date"
                        formControlName="startdate"
                        [disabled]="!minDate"
                        min="{{ minDate }}"
                        max="{{ maxDate }}"
                    />
                </div>
                <div class="item-type row">
                    <input
                        type="date"
                        class="input-date"
                        formControlName="enddate"
                        min="{{ minDate }}"
                        max="{{ maxDate }}"
                    />
                </div>
            </div>
        </div>
        <div class="content-value row">
            <app-table
                [model]="recordsModel"
                (paginatorEvent)="paginatorEvent($event)"
                (pageSizeEvent)="pageSizeEvent($event)"
                [removeCard]="true"
                [hideIndex]="true"
                (getRecords)="getTotalRecords($event)"
            ></app-table>
        </div>
    </div>
    <div class="footer">
        <div class="button-content row">
            <button class="btn btn2" (click)="closeModalViewRecords()">
                Cancelar
            </button>
            <button class="btn btn1" [type]="'submit'">Confirmar</button>
        </div>
    </div>
</form>
<ng-container *ngIf="loading">
    <app-modal-loading [loading]="true"> </app-modal-loading>
</ng-container>
<ng-container *ngIf="created">
    <app-modal-loading [success]="true" [title]="title"> </app-modal-loading>
</ng-container>
<ng-container *ngIf="error">
    <app-modal-loading [error]="true" title="title"> </app-modal-loading>
</ng-container>

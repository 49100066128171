import {Card} from '@/models/card';
import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Field, TableViewModel} from '@components/models/table';
import {ApiService} from '@services/api.service';
import {AppService} from '@services/app.service';
import {ExcelService} from '@services/excel.service';
import {SwitchService} from '@services/switch.service';
import {ToastrService} from 'ngx-toastr';
import {CallDataCdr, DataCdr} from "@/models/datacdrDto";
import {CDRDto} from "@/models/cdrDto";
import {DataTransaction} from "@/models/transactionDto";
import Swal from "sweetalert2";
import {DataReloadHistory} from "@/models/reloadHistoryDto";
import {ResultSimDoctorDto} from "@/models/simDoctor";
// import {SimDoctorResponseDto} from "@/models/simDoctor";

@Component({
  selector: 'app-sim-doctor',
  templateUrl: './sim-doctor.component.html',
  styleUrls: ['./sim-doctor.component.scss']
})
export class SimDoctorComponent implements OnInit {

  dataModelSimDoctor: TableViewModel = {
    fields: [],
    records: [],
    totalRecords: [],
    showFilters: true,
    enableActions: false
  };
  dataModelCheckDataSession: TableViewModel = {
    fields: [],
    records: [],
    totalRecords: [],
    showFilters: true,
    enableActions: false
  };
  dataModelGetLuActivity: TableViewModel = {
    fields: [],
    records: [],
    totalRecords: [],
    showFilters: true,
    enableActions: false
  };
  dataModelGetLu: TableViewModel = {
    fields: [],
    records: [],
    totalRecords: [],
    showFilters: true,
    enableActions: false
  };

  columGBalance: Field[] = [];
  columCheckDataSession: Field[] = [];
  columsGetLu: Field[] = [];
  columsGetLastActivity: Field[] = [];


  records: any;
  records_count: number = null;
  dataSource: any = null;
  currentPage: number = 1;
  pageSize: number = 3;
  minDate: string;
  maxDate: string;
  Simcards: Card[] = [];
  SelectSimcards: Card[] = [];
  cards: any = [];
  loadingSimcards: boolean = true;
  simcards_print: string;
  loading: boolean;
  rotate_icon_reset: boolean = false;
  SimcardsForm = new FormControl([], Validators.required);
  created: boolean = false;
  error: boolean = false;
  title: string;
  showTableHistory: boolean = false;
  typeTableHistory: string = 'dcdr';
  showForm: boolean = true;
  name_gbalance = 'análisis de registros';
  name_checkdatasession = 'Datos de sesión activa';
  name_getlvactivity = 'Última actividad';
  name_getlu = 'Historial de registro';
  resultDTO: ResultSimDoctorDto;
  result_reset: string;
  sim_reset: string;
  mensajePin: string = 'ping';
  numeroPin: string = '37259220373';


  @Input()
  card_selected: any;

  public recordsForm: FormGroup;

  constructor(
    private apiService: ApiService,
    private appService: AppService,
    private cd: ChangeDetectorRef,
    private modalService: SwitchService,
    private toastr: ToastrService,
    private excelService: ExcelService
  ) {
  }

  ngOnInit(): void {

    this.columGBalance = [
      {title: 'MSISDN', key: 'msisdn', filter: false, data: []},
      {title: 'IMSI', key: 'imsi', filter: false, data: []},
      {title: 'Tipo de producto', key: 'product_type', filter: false, data: []},
      {title: 'Tipo de SIM', key: 'sim_form_factor', filter: false, data: []},
      {title: 'Datos', key: 'data', filter: false, data: []},
      {title: 'SMS', key: 'sms', filter: false, data: []},
      {title: 'Llamadas de voz', key: 'voice', filter: false, data: []},
      {title: '4G', key: 'cuatrog', filter: false, data: []}
    ];

    this.columCheckDataSession = [
      {title: 'MSISDN', key: 'msisdn', filter: false, data: []},
      {title: 'Inicio de sesión', key: 'session_start', filter: false, data: []},
      {title: 'MCCMNC', key: 'mccmnc', filter: false, data: []},
      {title: 'País', key: 'country', filter: false, data: []},
      {title: 'Operador', key: 'operator', filter: false, data: []}
    ];

    this.columsGetLastActivity = [
      {title: 'Tipo de servicio', key: 'service_type', filter: false, data: []},
      {title: 'Fecha', key: 'time_stamp', filter: false, data: []},
      {title: 'MB', key: 'cost', filter: false, data: []},
      // {title: 'Currency', key: 'currency', filter: false, data: []},
      {title: 'Duración', key: 'duration', filter: false, data: []},
      {title: 'Canatidad', key: 'amount', filter: false, data: []},
      {title: 'País', key: 'country', filter: false, data: []},
    ];

    this.columsGetLu = [
      {title: 'Fecha', key: 'date', filter: false, data: []},
      {title: 'MSISDN', key: 'msisdn', filter: false, data: []},
      {title: 'IMSI', key: 'imsi', filter: false, data: []},
      {title: 'Valor ID', key: 'vlr_id', filter: false, data: []},
      {title: 'MCC', key: 'mcc', filter: false, data: []},
      {title: 'MNC', key: 'mnc', filter: false, data: []},
      {title: 'Tipo de registro', key: 'registration_type', filter: false, data: []},
      {title: 'País', key: 'country', filter: false, data: []},
      {title: 'Operador', key: 'operator', filter: false, data: []}
    ];


    this.apiService.getCardsInfo().then(async (cards) => {
      this.Simcards = cards.result;
      this.SelectSimcards = cards.result;
      this.loadingSimcards = false;
    });
    this.modalService.$created.subscribe((value) => {
      this.created = value;
    });
    this.modalService.$error.subscribe((value) => {
      this.error = value;
    });

    this.recordsForm = new FormGroup({
      sim: new FormControl(null, Validators.required)
    });
    let date = new Date();
    let minDate = new Date();
    minDate.setDate(minDate.getDate() - 90);

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    let minDay = minDate.getDate();
    let minMonth = minDate.getMonth() + 1;
    let minYear = minDate.getFullYear();

    if (month < 10) {
      this.maxDate = `${year}-0${month}-${day}`;
    } else {
      this.maxDate = `${year}-${month}-${day}`;
    }

    if (minMonth < 10) {
      this.minDate = `${minYear}-0${minMonth}-${minDay}`;
    } else {
      this.minDate = `${minYear}-${minMonth}-${minDay}`;
    }
  }

  ngAfterViewInit() {
    console.log(this.minDate);
    console.log(this.maxDate);
  }

  searchSim(value: string) {
    this.SelectSimcards = this.Simcards.filter((option) => {
      const itemData = option.onum.toUpperCase();
      const filter = value.toUpperCase();
      return itemData.indexOf(filter) > -1;
    });
  }

  async getSimStatus() {
    var data = {
      title: 'Estado De Simcard',
      header: [],
      data: []
    };
    console.log(this.recordsForm);
    if (this.recordsForm.valid) {
      this.loading = true;
      await this.apiService
        .getSimStatus(
          this.recordsForm.value.sim
        )
        .then(async (records) => {
          if (records.result) {
            this.resultDTO = records;
            this.records = this.resultDTO.result;
            // this.sim_reset = this.records.get_lu[0].msisdn;
            this.records_count = this.resultDTO.count;
            this.showForm = true;
            this.showTableHistory = true;
            // tabla gbalance
            if (this.resultDTO != null) {
              this.dataModelSimDoctor.fields = this.columGBalance;
              this.dataModelSimDoctor.records = this.resultDTO.result.gbalance;
              console.log(this.dataModelSimDoctor.records);

            } else {
              this.showMessageErrorRequest();
            }
            // tabla checkdatasession
            if (this.resultDTO != null) {
              this.dataModelCheckDataSession.fields = this.columCheckDataSession;
              this.dataModelCheckDataSession.records = this.resultDTO.result.checkdatasession;
              console.log(this.dataModelCheckDataSession.records);

            } else {
              this.showMessageErrorRequest();
            }
            // tabla getluactivity
            if (this.resultDTO != null) {
              this.dataModelGetLuActivity.fields = this.columsGetLastActivity;
              this.dataModelGetLuActivity.records = this.resultDTO.result.get_last_activity;

            } else {
              this.showMessageErrorRequest();
            }
            // tabla getlu
            if (this.resultDTO != null) {
              this.dataModelGetLu.fields = this.columsGetLu;
              this.dataModelGetLu.records = this.resultDTO.result.get_lu;

            } else {
              this.showMessageErrorRequest();
            }

            // this.cd.detectChanges();
          } else {
            this.loading = false;
            this.appService.logout();
          }
        })
        .catch((e) => {
          console.log(e);
        });
      this.loading = false;
      // await this.excelService.generateExcel(data);
    } else {
      this.loading = false;
      this.toastr.error('Por favor seleccione una sim card');
    }
  }

  // selectAllUnits() {
  //   var json_units = [];
  //   for (let card of this.Simcards) {
  //     json_units.push(card['sim']);
  //   }
  //   console.log(json_units);
  //   this.SimcardsForm.setValue(json_units);
  // }
  // deleteAllUnits() {
  //   var json_units = [];
  //   this.SimcardsForm.setValue(json_units);
  // }

  closeModalViewRecords() {
    this.modalService.$modal.emit(false);
  }

  onChageReportType(event) {
    this.typeTableHistory = event;
    this.cd.detectChanges();
  }

  clickReturn(event: any) {
    this.showTableHistory = false;
    this.showForm = true;
  }

  showMessageErrorRequest(title?:string, text?:string) {
    Swal.fire({
      icon: 'error',
      title: title==undefined ? 'Oops...' : title,
      text: text==undefined ? 'La consulta no arrojo resultados!' : text,
      footer: ''
    })
  }

  simReset() {
    this.rotate_icon_reset = true;
    try {
      this.apiService.getSimreset(this.sim_reset).then((response) => {
        this.result_reset = response.result;
        if (this.result_reset === 'SUCCESS') {
          this.created = true;
          this.rotate_icon_reset = false;
          Swal.fire({
            icon: 'success',
            title: 'Reset exitoso',
            text: 'El reset se realizó con exito!',
            footer: ''
          })
          setTimeout(()=> this.created=false, 2000);
        } else if (this.result_reset !== 'SUCCESS') {
          this.rotate_icon_reset = false;
          this.showMessageErrorRequest('No hubo conexión con la sim', `Intente de nuevo en 15 minutos`);
          console.log(this.result_reset);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  checkSimStatus() {
    this.loading = true;
    var data = {
      from: this.numeroPin,
      to: this.sim_reset,
      msg: this.mensajePin
    };
    console.log(data);
    this.apiService.sendMessage(data).then((response) => {
      console.log(response);
      if (response.error) {
        this.loading = false;
        this.modalService.$error.emit(true);
        this.title = 'No se pudo enviar el ping, intente mas tarde.';
      } else {
        this.loading = false;
        this.modalService.$created.emit(true);
        this.title = 'Pin enviado con éxito!';
      }
    });
  }

  changeSimSelected(event) {
    this.sim_reset = event;
    this.showTableHistory = false;
  }
}

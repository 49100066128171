import {Component, HostBinding, OnInit, Renderer2} from '@angular/core';
import { NotificationsService } from '@components/notifications/notifications.service';

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
    @HostBinding('class') class = 'wrapper';
    public sidebarMenuOpened = true;
    show_notifications: boolean;

    constructor(
        private renderer: Renderer2,
        private notifyService: NotificationsService
    ) {}

    ngOnInit() {
        if (document.querySelector('app-root')) {
            this.renderer.removeClass(
                document.querySelector('app-root'),
                'login-page'
            );
            this.renderer.removeClass(
                document.querySelector('app-root'),
                'register-page'
            );
        }
        this.notifyService.$notifications.subscribe((value) => {
            this.show_notifications = value;
        });
    }

    toggleMenuSidebar() {
        if (this.sidebarMenuOpened) {
            this.renderer.removeClass(
                document.querySelector('app-root'),
                'sidebar-open'
            );
            this.renderer.addClass(
                document.querySelector('app-root'),
                'sidebar-collapse'
            );
            this.sidebarMenuOpened = false;
        } else {
            this.renderer.removeClass(
                document.querySelector('app-root'),
                'sidebar-collapse'
            );
            this.renderer.addClass(
                document.querySelector('app-root'),
                'sidebar-open'
            );
            this.sidebarMenuOpened = true;
        }
    }
}

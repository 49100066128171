import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  TemplateRef
} from '@angular/core';
import {TableViewModel} from '../models/table';
import {Select2OptionData} from 'ng-select2';
import {Options} from 'select2';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ExcelService} from '@services/excel.service';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {
  @Input()
  minHeight = 290;

  @Input()
  pageSize = 5;

  @Input()
  hideHeader?: boolean = false;

  @Input()
  showTableName: boolean = true;

  @Input()
  tableName?: string;

  @Input()
  tableType?: 'normal' | 'session' | 'network';

  @Input()
  hideIndex: boolean = false;

  @Input()
  model: TableViewModel;

  @Input()
  showPageSize?: boolean = false;

  @Input()
  showReturnReport?: boolean = false;

  @Input()
  removeCard: boolean;

  @Output() clickedReturn = new EventEmitter<any>();

  @Output()
  paginatorEvent = new EventEmitter<number>();

  @Output()
  pageSizeEvent = new EventEmitter<number>();

  @Output()
  getRecords = new EventEmitter<any>();

  @Output()
  searchEvent = new EventEmitter<string>();

  @Output()
  filterEvent = new EventEmitter();

  @Output()
  onHandleLabel = new EventEmitter();

  dropdownSettings = {};

  // Aux Variables
  selectedRow: number;

  @Input()
  rowTemplate: TemplateRef<any>;

  @Input()
  actionsTemplate: TemplateRef<any>;

  index: any;

  constructor(
    private modalService: NgbModal,
    private excelService: ExcelService
  ) {
  }

  public exampleData: Array<Select2OptionData>;
  public value: string[];

  ngOnInit(): void {
    if (!this.tableType) {
      this.tableType = 'normal';
    }
  }

  clickedRow(index: number) {
    if (this.selectedRow == index) {
      this.selectedRow = null;
    } else {
      this.selectedRow = index;
    }
  }

  clickedPage(page: number) {
    this.paginatorEvent.emit(page);
    this.selectedRow = null;
  }

  clickedPageSize(pageSize: number) {
    this.pageSizeEvent.emit(pageSize);
    this.pageSize = pageSize;
  }

  getTotalRecords(header: any) {
    this.getRecords.emit(header);
  }

  updateFilterStatus(key: string, prevOrder: string) {
    var selectedField = this.model.fields.find((field) => field.key === key);
    selectedField.filterOrder = this.getNextFilterState(selectedField.filterOrder);
    this.filterEvent.emit({key: key, order: selectedField.filterOrder});
  }

  changeSearch(searchby: string) {
    this.searchEvent.emit(searchby);
  }

  onChangeLabel(event: any, field: any) {
    const labelValue = {
      label: event.target.value,
      sim: field.onum
    };
    this.onHandleLabel.emit(labelValue);
  }

  public valueChanged(event: string) {
  }

  public modelChanged(event: string) {
    this.paginatorEvent.emit();
  }

  open(content) {
    this.modalService.open(content, {centered: true, size: 'xl'});
    this.paginatorEvent.emit(1);
  }

  getMBData(costo: string) {
    let costo_int: number = +costo;
    let res = costo_int / 0.02
    res = +res.toFixed(2);
    return res
  }

  onClickReturn(event) {
    this.clickedReturn.emit(event);
  }

  getNextFilterState(prev: string)
  {
    let next: string;
    switch (prev) {
      case 'asc':
        next = 'desc'
        break;
      case 'desc':
        next = 'none'
        break;
      default:
        next = 'asc'
        break;
    }
    return next
  }
}

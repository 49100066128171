import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit, 
  ViewChild
} from '@angular/core';
import {AppService} from '@services/app.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {Card} from '@/models/card';
import {MatSort} from '@angular/material/sort';
import {TableViewModel} from '@components/models/table';
import {ApiService} from '@services/api.service';
import {MatDialog} from '@angular/material/dialog';
import {Message} from '@/models/Message';
import Swal from 'sweetalert2';
import {SwitchService} from '@services/switch.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {ExcelService} from '@services/excel.service';
import {Console} from 'console';
import {CardTruphone} from "@/models/cardTruphone";

@Component({
  selector: 'app-manage-sim-plan',
  templateUrl: './sim-with-plan.component.html',
  styleUrls: ['./sim-with-plan.component.scss']
})
export class SimWithPlanComponent implements AfterViewInit {
  public labelForm: FormControl = new FormControl();

  account: any;
  cards: CardTruphone[] = null;
  cards_count: number = null;
  cardsModel: TableViewModel = {
    title: 'Tarjetas SIM CON PLAN',
    description: '',
    filter: {
      page: 1,
      pageSize: 5
    },
    fields: [
      {title: 'Telefono', key: 'primaryMsisdn', filter: true, data: []},
      {title: 'ICC', key: 'iccid', filter: true, data: []},
      {title: 'Dispositivo gps', key: 'imei', filter: true, data: []},
      {title: 'Voz móvil', key: 'voiceMo', filter: false, data: []},
      {title: 'Sms móvil', key: 'smsMo', filter: false, data: []},
      {title: 'gprs', key: 'gprs', filter: false, data: []},
      {title: 'ussd', key: 'ussd', filter: false, data: []},
      {title: 'Saldo', key: 'saldo', filter: false, data: []},
    ],
    records: [],
    totalRecords: [],
    showFilters: true,
    enableActions: true
  };

  records: any;
  records_count: number = null;
  recordsModel: TableViewModel = {
    title: 'Records',
    fields: [
      {title: 'Id Sim', key: 'tsimid', filter: false, data: []},
      {title: 'Fecha', key: 'calldate', filter: false, data: []},
      {title: 'Numero', key: 'onum', filter: false, data: []},
      {
        title: 'Codigo de operador',
        key: 'opercode',
        filter: false,
        data: []
      },
      {title: 'Operador', key: 'operator', filter: false, data: []},
      {title: 'Pais', key: 'country', filter: false, data: []}
    ],
    records: [],
    showFilters: false,
    enableActions: false
  };

  dataSource: any = null;
  currentPage: number = 1;
  pageSize: number = 5;
  searchby: string = '';
  sortby: string = '';
  order: 'asc' | 'desc' | 'none' = 'none';
  newMessage: Message = null;
  showModalRecharge: boolean;
  show_modal: boolean;
  show_share_balance: boolean;
  show_automatic_transfer: boolean;
  show_send_message: boolean;
  show_view_records: boolean;
  loading: boolean;
  card_selected: CardTruphone;
  maxBlance: number;
  gettingMaxBalance: boolean = false;
  show_warning_transaction: boolean = false;
  created: boolean = false;
  error: boolean = false;
  title: string;
  loading2: boolean = true;
  edit_transaction: boolean = true;
  show_transaction_btn: boolean = true;
  info_transaction: any;
  sending_transfer: boolean = false;
  stepValue: string;
  maxLimit: any;
  arrValues: any;
  arrMinValues: any;
  transactionOption: any;
  minLimitOption: any;

  public transferForm: FormGroup;
  public messageForm: FormGroup;
  public recordsForm: FormGroup;
  public updatecard: FormGroup;

  constructor(
    private apiService: ApiService,
    private appService: AppService,
    private cd: ChangeDetectorRef,
    public dialog: MatDialog,
    private modalService: SwitchService,
    private toastr: ToastrService,
    private excelService: ExcelService
  ) {
  }

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  ngOnInit(): void {
    this.updatecard = new FormGroup({
      corp_minlimit: new FormControl(null, Validators.required),
      corp_transaction: new FormControl(null, Validators.required),
      corp_maxlimit: new FormControl(null, Validators.required)
    });
    this.transferForm = new FormGroup({
      value: new FormControl(null, Validators.required),
      type: new FormControl('get', Validators.required)
    });
    this.messageForm = new FormGroup({
      body: new FormControl(null, Validators.required)
    });
    this.recordsForm = new FormGroup({
      startdate: new FormControl(null, Validators.required),
      enddate: new FormControl(null, Validators.required)
    });
    this.modalService.$modal.subscribe((value) => {
      this.show_modal = value;
    });
    this.modalService.$card_selected.subscribe((value) => {
      this.card_selected = value;
    });
    this.modalService.$created.subscribe((value) => {
      this.created = value;
    });
    this.modalService.$error.subscribe((value) => {
      this.error = value;
    });
  }

  ngAfterViewInit() {
    this.appService
      .getAccountInfo()
      .then((account) => {
        this.account = account;
      });
    this.getCardsTruphone(this.currentPage, this.pageSize, this.searchby);
  }

  onChangeCorpTransaction() {
    this.maxLimit = this.updatecard.controls['corp_maxlimit'].value;
    let maxLimitInt = parseInt(this.maxLimit);
    this.arrValues = [];
    this.arrValues.push(this.transactionOption);
    let moduleValues = [];
    for (let i = 1; i <= maxLimitInt; i++) {
      moduleValues.push(i);
    }
    moduleValues.reverse().map(val => {
      if (maxLimitInt % val === 0 && val % 5 === 0) {
        if (val !== this.maxLimit) {
          this.arrValues.push(String(val));
        }
      }
    });
  }

  onChangeMinCorpTransaction() {
    this.maxLimit = this.updatecard.controls['corp_maxlimit'].value;
    let maxLimitMin = parseFloat(this.maxLimit);
    this.arrMinValues = [];
    let val = 0.07;
    for (let i = 1; i <= maxLimitMin; i++) {
      if (i % 5 === 0) {
        this.arrMinValues.push(String(i));
        val = 0;
      } else {
        this.arrMinValues.push(String(i + val));
      }
      val += 0.07;
    }
  }

  async onChangesType() {
    this.cleanValues();
    await this.changeMaxBalance();
    this.cd.detectChanges();
  }

  async changeMaxBalance() {
    this.cleanValues();
    this.gettingMaxBalance = true;
    if (this.transferForm.value.type === 'get') {
      await this.apiService.getBalanceTransfer('sim', this.card_selected.primaryMsisdn).then((res) => {
        this.maxBlance = res.dataBalance;
        this.gettingMaxBalance = false;
      });
    } else {
      await this.apiService.getBalanceTransfer('group', this.account.group_id).then((res) => {
        this.maxBlance = res.data_balance;
        this.gettingMaxBalance = false;
      });
    }
  }

  getCardsTruphone(
    currentPage: number,
    pageSize: number,
    searchby?: string,
    sortby?: string,
    order?: string) {
    this.apiService
      .getTruphoneCardsInfo(currentPage, pageSize, searchby, sortby, order)
      .then((cards) => {
        if (cards.result) {
          this.cards = cards.result;
          this.cards_count = cards.total_cards_count;
          this.cardsModel.records = this.cards;
          this.cardsModel.pagination = {
            currentPage: this.currentPage,
            pageSize: this.pageSize,
            pageCount: cards.count,
            rowCount: this.cards_count
          };

          this.loading2 = false;
          this.cd.detectChanges();
        } else {
          this.logout();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  getTotalCards(header: any) {
    console.log(header);
    this.apiService
      .getTruphoneCardsInfo(this.currentPage, this.pageSize,)
      .then(async (cards) => {
        if (cards.result) {
          this.cardsModel.totalRecords = cards.result;
          var data = {
            title: header.title,
            header: header.header,
            data: []
          };
          for await (let card of cards.result) {
            var row = [];
            for await (let key of header.keys) {
              row.push(card[key]);
            }
            data.data.push(row);
          }
          await this.excelService.generateExcel(data);
        } else {
          this.logout();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  sendMessageTruphone() {
    this.loading = true;
    if (this.messageForm.valid) {
      let data = {
        to: this.card_selected.iccid,
        msg: this.messageForm.value.body
      };
      this.apiService.sendMessageTruphone(data.msg, data.to).then((response) => {
        console.log("test send mesagge response of api");
        console.log(response);
        if (response.error) {
          this.modalService.$error.emit(true);
          this.title = 'Ocurrió un error en el servidor!';
        } else {
          this.loading = false;
          this.modalService.$modal.emit(false);
          this.modalService.$created.emit(true);
          this.title = 'Mensaje enviado con éxito!';

          this.getCardsTruphone(
            this.currentPage,
            this.pageSize,
            this.searchby
          );
        }
      });
    } else {
      this.modalService.$modal.emit(false);
      this.modalService.$created.emit(false);
      this.title = 'Mensaje no enviado!';
      this.loading = false;
      this.toastr.error('Faltan datos en el formulario!');
    }
  }

  logout() {
    this.appService.logout();
  }

  onRemove(index: number) {
    console.log('remove event ! ');
  }

  paginatorEvent(currentPage: number) {
    this.currentPage = currentPage;
    this.getCardsTruphone(currentPage, this.pageSize, this.searchby);
  }

  pageSizeEvent(pageSize: number) {
    this.pageSize = pageSize;
    this.paginatorEvent(this.currentPage);
  }

  searchP(searchby: string) {
    console.log(searchby)
    this.searchby = searchby;
    this.getCardsTruphone(
      this.currentPage,
      this.pageSize,
      searchby ? searchby : this.searchby
    );
  }



  async getTransaction(sim: string) {
    await this.apiService.getInfoTransaction(sim).then((info) => {
      this.info_transaction = info.result[0];
    });
  }

  async openModalAutoTransfer(to: Card) {
    this.cleanValues();
    await this.getTransaction(to.onum);
    this.modalService.$modal.emit(true);
    this.modalService.$card_selected.emit(to);
    this.updatecard.controls['corp_maxlimit'].setValue(this.info_transaction.max_limit);
    this.transactionOption = this.info_transaction.transaction;
    this.minLimitOption = this.info_transaction.min_limit;
    console.log(this.info_transaction.max_limit);
    console.log(this.info_transaction.transaction);
    console.log(this.info_transaction.min_limit);
    this.show_automatic_transfer = true;
    this.show_share_balance = false;
    this.show_share_balance = false;
    this.show_send_message = false;
    this.show_view_records = false;
    this.edit_transaction = true;
    this.show_transaction_btn = true;
  }

  openModalSendMessage(to: Card) {
    this.modalService.$modal.emit(true);
    this.modalService.$card_selected.emit(to);
    this.show_automatic_transfer = false;
    this.show_share_balance = false;
    this.show_send_message = true;
    this.show_view_records = false;
  }

  closeModalSendMessage() {
    this.modalService.$modal.emit(false);
    this.show_share_balance = false;
    this.show_send_message = false;
    this.show_view_records = false;
  }

  openModalViewRecords(to: Card) {
    this.modalService.$modal.emit(true);
    this.modalService.$card_selected.emit(to);
    this.show_share_balance = false;
    this.show_send_message = false;
    this.show_view_records = true;
  }

  closeModalViewRecords() {
    this.modalService.$modal.emit(false);
    this.show_share_balance = false;
    this.show_send_message = false;
    this.show_view_records = false;
  }

  openDialog(to: Card) {
    Swal.fire({
      title: `Submit message to ${to.onum}`,
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Look up',
      showLoaderOnConfirm: true,
      preConfirm: (message) => {
        return fetch(`//api.github.com/users/${message}`)
          .then((response) => {
            if (!response.ok) {
              throw new Error(response.statusText);
            }
            return response.json();
          })
          .catch((error) => {
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: `${result.value.login}'s avatar`,
          imageUrl: result.value.avatar_url
        });
      }
    });
  }

  onHandleLabel(labelValue) {
    this.apiService.updateSimLabel(labelValue).then(() => {
      this.getCardsTruphone(this.currentPage, this.pageSize, this.searchby);
    });
  }
  closeModalShareBalance() {
    this.cleanValues();
    this.modalService.$modal.emit(false);
    this.show_share_balance = false;
    this.show_send_message = false;
    this.show_view_records = false;
    this.show_transaction_btn = true;
  }
  changeFilter(event) {

    // this.cardsModel.records = [];
    this.order = event.order;
    this.sortby = event.key;
    this.getCardsTruphone(this.currentPage, this.pageSize, null, this.sortby, this.order);
  }

  cleanValues() {
    this.maxBlance = null;
    this.arrValues = [];
    this.arrMinValues = [];
    this.transactionOption = '';
    this.minLimitOption = '';
  }
}

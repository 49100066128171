<ng-template #actionsTemplate  let-index="index" let-$implicit="$implicit">
  <div class="row m-0 p-0 w-100">
    <div class="d-flex align-items-center justify-content-center h-100 w-100">
      <a class="btn btn-icon btn-light btn-sm">
                <span [inlineSVG]="'./assets/icons/General/Settings-1.svg'"
                      class="svg-icon svg-icon-md svg-icon-primary"></span>
      </a>
      <a matTooltip="Editar" class="btn btn-icon btn-light btn-sm mx-3">
                <span [inlineSVG]="'./assets/icons/Communication/Write.svg'"
                      class="svg-icon svg-icon-md svg-icon-warning"></span>
      </a>

    </div>
  </div>
</ng-template>

<app-table [model]='cardsModel' [removeCard]='true' (paginatorEvent)='paginatorEvent($event)' (pageSizeEvent)="pageSizeEvent($event)"
           [actionsTemplate]="actionsTemplate" [hideIndex]="true"></app-table>




import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TableViewModel} from '@components/models/table';
import {ApiService} from '@services/api.service';
import {AppService} from '@services/app.service';
import {ExcelService} from '@services/excel.service';
import {SwitchService} from '@services/switch.service';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-records',
    templateUrl: './records.component.html',
    styleUrls: ['./records.component.scss']
})
export class RecordsComponent implements OnInit {
    records: any;
    records_count: number = null;
    recordsModel: TableViewModel = {
        title: 'Records',
        fields: [
            {title: 'Id Sim', key: 'tsimid', filter: false, data: []},
            {title: 'Fecha', key: 'calldate', filter: false, data: []},
            {title: 'Numero', key: 'onum', filter: false, data: []},
            {
                title: 'Codigo de operador',
                key: 'opercode',
                filter: false,
                data: []
            },
            {title: 'Operador', key: 'operator', filter: false, data: []},
            {title: 'Pais', key: 'country', filter: false, data: []},
            {title: 'Consumo (bytes)', key: 'inb', filter: false, data: []},
            {title: 'Costo (USD)', key: 'cost', filter: false, data: []}
        ],
        records: [],
        showFilters: false,
        enableActions: false,
        isModal: true
    };

    dataSource: any = null;
    currentPage: number = 1;
    pageSize: number = 3;
    loading: boolean;
    minDate: string;
    maxDate: string;
    created: boolean = false;
    error: boolean = false;
    title: string;

    @Input()
    card_selected: any;

    public recordsForm: FormGroup;

    constructor(
        private apiService: ApiService,
        private appService: AppService,
        private cd: ChangeDetectorRef,
        private modalService: SwitchService,
        private toastr: ToastrService,
        private excelService: ExcelService
    ) {}

    ngOnInit(): void {
        this.recordsForm = new FormGroup({
            startdate: new FormControl(null, Validators.required),
            enddate: new FormControl(null, Validators.required)
        });
        this.modalService.$created.subscribe((value) => {
            this.created = value;
        });
        this.modalService.$error.subscribe((value) => {
            this.error = value;
        });
        let date = new Date();
        let minDate = new Date();
        minDate.setDate(minDate.getDate() - 90);

        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();

        let minDay = minDate.getDate();
        let minMonth = minDate.getMonth() + 1;
        let minYear = minDate.getFullYear();

        if (month < 10) {
            this.maxDate = `${year}-0${month}-${day}`;
        } else {
            this.maxDate = `${year}-${month}-${day}`;
        }

        if (minMonth < 10) {
            this.minDate = `${minYear}-0${minMonth}-${minDay}`;
        } else {
            this.minDate = `${minYear}-${minMonth}-${minDay}`;
        }
    }

    ngAfterViewInit() {
        console.log(this.minDate);
        console.log(this.maxDate);
    }

    getRecords() {
        this.loading = true;
        console.log(this.recordsForm.value.startdate);
        if (this.recordsForm.valid) {
            this.apiService
                .getCDR(
                    this.currentPage,
                    this.pageSize,
                    this.card_selected.sim,
                    this.recordsForm.value.startdate,
                    this.recordsForm.value.enddate
                )
                .then((records) => {
                    if (records.result) {
                        this.loading = false;
                        this.records = records.result;
                        this.records_count = records.total_records_count;
                        this.recordsModel.records = this.records;
                        this.recordsModel.pagination = {
                            currentPage: this.currentPage,
                            pageSize: this.pageSize,
                            pageCount: records.count,
                            rowCount: this.records_count
                        };
                        console.log(records.result);
                        this.cd.detectChanges();
                    } else {
                        this.loading = true;
                        this.appService.logout();
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        } else {
            this.loading = false;
            this.toastr.error('Faltan datos en el formulario!');
        }
    }

    getTotalRecords(header: any) {
        console.log('a la funcion llega');
        this.apiService
            .getCDR(
                null,
                null,
                this.card_selected.sim,
                this.recordsForm.value.startdate,
                this.recordsForm.value.enddate
            )
            .then(async (record) => {
                if (record.result) {
                    this.recordsModel.totalRecords = record.result;
                    var data = {
                        title: 'Historial',
                        header: header.header,
                        data: []
                    };
                    for await (let card of record.result) {
                        var row = [];
                        for await (let key of header.keys) {
                            row.push(card[key]);
                        }
                        data.data.push(row);
                    }
                    await this.excelService.generateExcel(data);
                } else {
                    // this.logout();
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    paginatorEvent(currentPage: number) {
        this.apiService
            .getCDR(
                currentPage,
                this.pageSize,
                this.card_selected.sim,
                this.recordsForm.value.startdate,
                this.recordsForm.value.enddate
            )
            .then((records) => {
                if (records.result) {
                    this.records = records.result;
                    this.records_count = records.total_records_count;
                    this.recordsModel.records = this.records;
                    this.recordsModel.pagination = {
                        currentPage: this.currentPage,
                        pageSize: this.pageSize,
                        pageCount: records.count,
                        rowCount: this.records_count
                    };
                    this.cd.detectChanges();
                } else {
                    this.appService.logout();
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    pageSizeEvent(pageSize: number) {
        this.pageSize = pageSize;
        this.paginatorEvent(this.currentPage);
    }

    closeModalViewRecords() {
        this.modalService.$modal.emit(false);
    }
}

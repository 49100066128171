<nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <ul class="navbar-nav">
        <li class="nav-item">
            <a class="nav-link" (click)="toggleMenuSidebar.emit()" role="button"
                ><i class="fas fa-bars"></i
            ></a>
        </li>
    </ul>

    <a [routerLink]="['/']" class="brand-link sidebar-white">
        <img
            src="assets/images/mi-sim-favicon.png"
            alt="MI SIM Logo"
            class="brand-image"
        />
        <span class="brand-text">MI Sim</span>
    </a>

    <ul class="navbar-nav ml-auto">
        <!--        <app-messages-dropdown-menu></app-messages-dropdown-menu>-->
        <!-- <app-notifications></app-notifications>  -->
        <a
            class="nav-link"
            data-toggle="dropdown"
            (click)="toggleNotifications()"
        >
            <i class="far fa-bell"></i>
            <span class="badge badge-warning navbar-badge">{{
                notifications_new
            }}</span>
        </a>

        <!--        <app-language-dropdown></app-language-dropdown>-->
        <app-user-dropdown-menu></app-user-dropdown-menu>
        <!-- <li class="nav-item">
      <a
        class="nav-link"
        data-widget="control-sidebar"
        data-slide="true"
        href="#"
        role="button"
      >
        <i class="fas fa-th-large"></i>
      </a>
    </li> -->
    </ul>
</nav>

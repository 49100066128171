import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';

import {AppRoutingModule} from '@/app-routing.module';
import {AppComponent} from './app.component';
import {MainComponent} from '@modules/main/main.component';
import {LoginComponent} from '@modules/login/login.component';
import {HeaderComponent} from '@modules/main/header/header.component';
import {FooterComponent} from '@modules/main/footer/footer.component';
import {MenuSidebarComponent} from '@modules/main/menu-sidebar/menu-sidebar.component';
import {BlankComponent} from '@pages/blank/blank.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ProfileComponent} from '@pages/profile/profile.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RegisterComponent} from '@modules/register/register.component';
import {DashboardComponent} from '@pages/dashboard/dashboard.component';
import {ToastrModule} from 'ngx-toastr';
import {MessagesDropdownMenuComponent} from '@modules/main/header/messages-dropdown-menu/messages-dropdown-menu.component';
import {AppButtonComponent} from './components/app-button/app-button.component';

import {registerLocaleData} from '@angular/common';
import localeEs from '@angular/common/locales/es';
import {UserDropdownMenuComponent} from '@modules/main/header/user-dropdown-menu/user-dropdown-menu.component';
import {ForgotPasswordComponent} from '@modules/forgot-password/forgot-password.component';
import {RecoverPasswordComponent} from '@modules/recover-password/recover-password.component';
import {LanguageDropdownComponent} from '@modules/main/header/language-dropdown/language-dropdown.component';
import {PrivacyPolicyComponent} from './modules/privacy-policy/privacy-policy.component';
import {ManageAccountComponent} from './pages/manage-account/manage-account.component';
import {ManageFundComponent} from './pages/manage-fund/manage-fund.component';
import {ManageSimComponent} from './pages/manage-sim/manage-sim.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {TableComponent} from './components/table/table.component';
import {PaginationComponent} from './components/pagination/pagination.component';
import {InlineSVGModule} from 'ng-inline-svg';
import {PopupSelectComponent} from './components/popup-select/popup-select.component';
import {AnimatedCounterComponent} from './components/animated-counter/animated-counter.component';
import {MatDialogModule} from '@angular/material/dialog';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {ModalComponent} from './components/modal/modal.component';
import {NotificationsComponent} from './components/notifications/notifications.component';
import {ManageMessagesComponent} from './pages/manage-messages/manage-messages.component';
import { RecordsComponent } from './components/records/records.component';
import { CardDashComponent } from './components/card-dash/card-dash.component';
import { CardModalComponent } from './components/card-modal/card-modal.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ExcelReportComponent } from './pages/excel-report/excel-report.component';
import {MatSelectModule} from '@angular/material/select';
import { ModalLoadingComponent } from './components/modal-loading/modal-loading.component';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatIconModule} from "@angular/material/icon";
import { SimDoctorComponent } from './pages/sim-doctor/sim-doctor.component';
import { SimWithPlanComponent } from './pages/sim-with-plan/sim-with-plan.component';



registerLocaleData(localeEs, 'es');

@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        LoginComponent,
        HeaderComponent,
        FooterComponent,
        MenuSidebarComponent,
        BlankComponent,
        ProfileComponent,
        RegisterComponent,
        DashboardComponent,
        MessagesDropdownMenuComponent,
        AppButtonComponent,
        UserDropdownMenuComponent,
        ForgotPasswordComponent,
        RecoverPasswordComponent,
        LanguageDropdownComponent,
        PrivacyPolicyComponent,
        ManageAccountComponent,
        ManageFundComponent,
        ManageSimComponent,
        TableComponent,
        PaginationComponent,
        PopupSelectComponent,
        AnimatedCounterComponent,
        ModalComponent,
        NotificationsComponent,
        ManageMessagesComponent,
        RecordsComponent,
        CardDashComponent,
        CardModalComponent,
        ExcelReportComponent,
        ModalLoadingComponent,
        SimDoctorComponent,
        SimWithPlanComponent,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        MatTooltipModule,
        MatSelectModule,
        ToastrModule.forRoot({
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
            preventDuplicates: true
        }),
        MatFormFieldModule,
        MatDatepickerModule,
        FormsModule,
        MatTableModule,
        MatPaginatorModule,
        InlineSVGModule,
        MatDialogModule,
        SweetAlert2Module,
        MatProgressSpinnerModule,
        MatIconModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {}

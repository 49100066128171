import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from '@modules/main/main.component';
import {BlankComponent} from '@pages/blank/blank.component';
import {LoginComponent} from '@modules/login/login.component';
import {ProfileComponent} from '@pages/profile/profile.component';
import {RegisterComponent} from '@modules/register/register.component';
import {DashboardComponent} from '@pages/dashboard/dashboard.component';
import {AuthGuard} from '@guards/auth.guard';
import {NonAuthGuard} from '@guards/non-auth.guard';
import {ForgotPasswordComponent} from '@modules/forgot-password/forgot-password.component';
import {RecoverPasswordComponent} from '@modules/recover-password/recover-password.component';
import {PrivacyPolicyComponent} from '@modules/privacy-policy/privacy-policy.component';
import {ManageAccountComponent} from './pages/manage-account/manage-account.component';
import {ManageFundComponent} from './pages/manage-fund/manage-fund.component';
import {ManageSimComponent} from './pages/manage-sim/manage-sim.component';
import {ManageMessagesComponent} from '@pages/manage-messages/manage-messages.component';
import { ExcelReportComponent } from '@pages/excel-report/excel-report.component';
import {SimDoctorComponent} from "@pages/sim-doctor/sim-doctor.component";
import { SimWithPlanComponent } from './pages/sim-with-plan/sim-with-plan.component';

const routes: Routes = [
    {
        path: '',
        component: MainComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            {
                path: 'account',
                component: ManageAccountComponent
            },
            {
                path: 'funds',
                component: ManageFundComponent
            },
            {
                path: 'sim',
                component: ManageSimComponent
            },
            {
                path: 'messages',
                component: ManageMessagesComponent
            },
            {
                path: 'excel-report',
                component: ExcelReportComponent
            },
            {
              path: 'sim-doctor',
              component: SimDoctorComponent
            },
            {
                path: '',
                component: DashboardComponent
            },
            {   path: 'sim-with-plan',
                component: SimWithPlanComponent
            }
        ]
    },
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'register',
        component: RegisterComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'recover-password',
        component: RecoverPasswordComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent,
        canActivate: [NonAuthGuard]
    },
    {path: '**', redirectTo: ''}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
    exports: [RouterModule]
})
export class AppRoutingModule {}

<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h2 class="title-main m-0">Simcards</h2>
      </div>
      <!-- /.col -->
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item">
            <a [routerLink]="['/']">Home</a>
          </li>
          <li class="breadcrumb-item active">Simcards</li>
        </ol>
      </div>
      <!-- /.col -->
    </div>
    <!-- /.row -->
  </div>
</div>

<ng-template #actionsTemplate let-index="index" let-$implicit="$implicit">
  <div class="row m-0 p-0 w-100">
    <div class="acionts-content">
      <div
        class="icon-td"
        (click)="openModalShareBalance($implicit)"
        matTooltip="Saldo"
      >
        <svg
          version="1.1"
          id="listos"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 100 100"
          style="enable-background: new 0 0 100 100"
          xml:space="preserve"
          class="table-icon"
        >
                    <path
                      fill="current"
                      d="M50,0C22.4,0,0,22.4,0,50s22.4,50,50,50s50-22.4,50-50S77.6,0,50,0L50,0z M54.9,80H55v7.7
           c-1.6,0.2-3.3,0.3-5,0.3s-3.4-0.1-5-0.3V80h0.1c-7.2-1.5-13-6.1-15.1-12.1l9.7-2.6c1.6,3,5.7,5.2,10.3,5.2c6,0,11-3.7,11-8
           c0-1-0.3-1.9-0.7-2.8c-1.6-3-5.7-5.2-10.3-5.2c-5.6,0-10.7-1.9-14.5-5C33,47.4,31,44.8,30,41.9c-0.6-1.7-1-3.5-1-5.4
           c0-8.4,6.8-15.6,16-17.5v-6.7c1.6-0.2,3.3-0.3,5-0.3s3.4,0.1,5,0.3V19c7.1,1.5,12.9,6.1,15,12.1l-9.7,2.6c-1.6-3-5.7-5.2-10.3-5.2
           c-6,0-11,3.7-11,8c0,1,0.3,1.9,0.7,2.8c1.6,3,5.7,5.2,10.3,5.2c5.6,0,10.7,1.9,14.5,5c2.5,2.1,4.5,4.7,5.5,7.6c0.6,1.7,1,3.5,1,5.4
           C71,71,64.1,78.1,54.9,80z"
                    />
                </svg>
      </div>
      <div
        class="icon-td"
        (click)="openModalSendMessage($implicit)"
        matTooltip="Mensaje"
      >
        <svg
          version="1.1"
          id="listos"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 100 100"
          style="enable-background: new 0 0 100 100"
          xml:space="preserve"
          class="table-icon"
        >
                    <path
                      fill="current"
                      d="M60,72v18c0,0.8-0.1,1.5-0.3,2.3c-1,4.4-5,7.8-9.7,7.8H10c-4.8,0-8.7-3.3-9.7-7.8C0.1,91.5,0,90.8,0,90V10
           C0,4.5,4.5,0,10,0h40c4.8,0,8.9,3.4,9.8,8H10c-1.1,0-2,0.9-2,2v78h44V72H60z M80,36H40v8h40V36z M92,24H28v28v8.7l2.3-2.3l2.3-2.3
           H36h56V24 M100,16v48H36L20,80V52V16H100L100,16z"
                    />
                </svg>
      </div>
    </div>
  </div>
</ng-template>

<ng-template [ngIf]="show_modal === true && show_share_balance === true">
  <app-card-modal [titleHeader]="'TRANSACCIÓN'" [color]="'#00249C'">
    <form
      class="transaction-modal column"
      [formGroup]="transferForm"
      (ngSubmit)="transferBalance()"
    >
      <div class="body column">
        <div class="content-type column">
          <!-- <div class="title-type">Tipo de recarga</div>
          <div class="body-type row">
              <div class="RecarManual">
                  <div class="item-type row">
                      <label class="containe-checks">
                          <input
                              type="radio"
                              name="recharge_type"
                              value="manual_recharge"
                              id="manual_recharge"
                              class="check-box-balance"
                              formControlName="recharge_type"
                          />
                          <span class="checkmark"></span>
                      </label>
                      <label for="manual_recharge"
                          >Recarga manual</label
                      >
                  </div>
              </div>
              <div class="RecarAutom">
                  <div class="item-type row">
                      <label class="containe-checks">
                          <input
                              class="check-box-balance"
                              type="radio"
                              value="automatic_recharge"
                              name="recharge_type"
                              id="automatic_recharge"
                              formControlName=""
                          />
                          <span class="checkmark"></span>
                      </label>
                      <label for="automatic_recharge"
                          >Recarga automatica</label
                      >
                  </div>
              </div>
          </div> -->
          <div class="ManualRecharge">
            <div class="title-type">Tipo de transacción</div>
            <div class="body-type row">
              <div class="RecarAutom">
                <div class="item-type row">
                  <label class="containe-checks">
                    <input
                      type="radio"
                      name="type"
                      value="get"
                      id="remove_balance"
                      class="check-box-balance"
                      formControlName="type"
                      (change)="onChangesType()"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <label for="remove_balance"
                  >Retirar saldo</label
                  >
                </div>
              </div>
              <div class="RecarAutom">
                <div class="item-type row">
                  <label class="containe-checks">
                    <input
                      class="check-box-balance"
                      type="radio"
                      value="put"
                      name="type"
                      id="add_balance"
                      formControlName="type"
                      (change)="onChangesType()"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <label for="add_balance"
                  >Añadir saldo</label
                  >
                </div>
              </div>
            </div>
            <div class="content-value row">
              <div class="content-input column">
                <div class="text-1">Valor</div>
                <input
                  type="number"
                  name="value"
                  id="value_balance"
                  class="input-value value-transfer-input"
                  formControlName="value"
                  max="{{ maxBlance }}"
                />
                <label for="value_balance" class="row">
                  <div>Disponibles</div>
                  <ng-container
                    *ngIf="
                                            maxBlance > 0 && !gettingMaxBalance;
                                            then available;
                                            else no_available
                                        "
                  ></ng-container>
                  <ng-template #available>
                                        <span class="mb-available"
                                        >{{ maxBlance }} Mb</span
                                        >
                  </ng-template>
                  <ng-template #no_available>
                    <ng-container
                      *ngIf="gettingMaxBalance"
                    >
                      <div class="loading-content-balance">
                        <mat-spinner [diameter]="30" mode="indeterminate"></mat-spinner>
                      </div>

                    </ng-container>
                    <ng-container
                      *ngIf="!gettingMaxBalance"
                    >
                                        <span class="not-available">
                                            No hay megas disponibles para
                                            retirar
                                        </span>
                    </ng-container>
                  </ng-template>
                </label>
              </div>
            </div>
            <div class="footer">
              <div class="button-content row">
                <button
                  class="btn btn2"
                  (click)="closeModalShareBalance()"
                >
                  Cancelar
                </button>
                <button class="btn btn1" [type]="'submit'">
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </app-card-modal>
</ng-template>

<ng-template [ngIf]="show_modal === true && show_automatic_transfer === true">
  <app-card-modal [titleHeader]="'RECARGA AUTOMATICA'" [color]="'#00249C'">
    <form class="transaction-modal column" [formGroup]="updatecard" (ngSubmit)="updateSimcard()">
      <div class="body column">
        <div class="content-type column">
          <div
            class="AutomaticRecharge"
          >
            <div class="content-value row">
              <div class="content-input column">
                <div class="text-1">Max Limite MB</div>
                <input
                  min="0"
                  step="5"
                  type="number"
                  name="corp_maxlimit"
                  class="input-value"
                  formControlName="corp_maxlimit"
                  (change)="onChangeMaxLimit($event)"
                  [readOnly]="edit_transaction"
                />
                <div class="text-1">Transacción MB</div>
                <select
                  type="text"
                  name="corp_transaction"
                  class="input-value"
                  formControlName="corp_transaction"
                  placeholder="Seleccione un valor"
                >
                  <option
                    [value]="transactionOption"
                    [selected]="true"
                    *ngIf="show_transaction_btn"
                  >{{transactionOption}}</option
                  >
                  <option
                    *ngFor="let val of arrValues"
                    [value]="val"
                  >{{val}}</option
                  >
                </select>
                <div class="text-1">Min Limite MB</div>

                <select
                  type="text"
                  name="corp_minlimit"
                  class="input-value"
                  formControlName="corp_minlimit"
                  placeholder="Seleccione un valor"
                >
                  <option
                    [value]="minLimitOption"
                    [selected]="true"
                    *ngIf="show_transaction_btn"
                  >{{minLimitOption}}</option
                  >
                  <option
                    *ngFor="let val of arrMinValues"
                    [value]="val"
                  >{{val}}</option
                  >
                </select>
                <label
                  *ngIf="show_warning_transaction"
                  class="red1 text-dart"
                  for=""
                >
                  El valor de transacción que esta ingresando
                  no es multiplo del valor limite.
                </label>
              </div>
            </div>
            <div class="footer">
              <div class="button-content row">
                <button
                  class="btn btn2"
                  (click)="closeModalShareBalance()"
                >
                  Cancelar
                </button>
                <button
                  class="btn btn1"
                  *ngIf="!show_transaction_btn"
                  [type]="'submit'"
                >
                  Confirmar
                </button>
                <button
                  class="btn btn1"
                  *ngIf="show_transaction_btn"
                  (click)="chageTransactionBtn()"
                >
                  Editar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </app-card-modal>
</ng-template>

<ng-template [ngIf]="show_modal === true && show_view_records === true">
  <app-card-modal [titleHeader]="'HISTORIAL'" [color]="'#01BEBD'">
    <app-records
      style="width: 100%"
      [card_selected]="card_selected"
    ></app-records>
  </app-card-modal>
</ng-template>
<ng-template [ngIf]="show_modal && show_send_message">
  <app-card-modal [titleHeader]="'NUEVO MENSAJE'" [color]="'#C6007E'">
    <form
      class="message-modal column"
      [formGroup]="messageForm"
      (ngSubmit)="sendMessage()"
    >
      <div class="body column">
        <div class="content-type column">
          <div class="title-type row">
            <div>Emisor</div>
            <div class="span">
              {{ card_selected.onum }}
            </div>
          </div>
          <div class="body-type row">
            <div class="item-type row">
                            <textarea
                              name="body"
                              id=""
                              formControlName="body"
                              class="text-body"
                            ></textarea>
            </div>
          </div>
        </div>
        <div class="content-value column">
          <div class="row text-dis">
            <div>Mensajes enviados</div>
            <div class="red1 text-dart">
              {{ card_selected.daily_count }} SMS
            </div>
          </div>
          <div class="data-list">
            <input
              list="sims"
              class="content-input"
              placeholder="Receptor"
              formControlName="to"
              type="number"
              min="0"
            />
            <datalist class="content-input" id="sims">
              <option
                *ngFor="let card of cards"
                [value]="card.onum"
              >
                {{ card.onum }}
              </option>
            </datalist>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="button-content row">
          <button class="btn btn4" (click)="closeModalShareBalance()">
            Cancelar
          </button>
          <button class="btn btn3" [type]="'submit'">Enviar</button>
        </div>
      </div>
    </form>
  </app-card-modal>
</ng-template>
<ng-container *ngIf="loading">
  <app-modal-loading [loading]="true"></app-modal-loading>
</ng-container>
<ng-container *ngIf="created">
  <app-modal-loading [success]="true" [title]="title"></app-modal-loading>
</ng-container>
<ng-container *ngIf="error">
  <app-modal-loading [error]="true" [title]="title"></app-modal-loading>
</ng-container>
<ng-container *ngIf="!loading2">
  <app-table
    [model]="cardsModel"
    [removeCard]="true"
    (paginatorEvent)="paginatorEvent($event)"
    (pageSizeEvent)="pageSizeEvent($event)"
    [actionsTemplate]="actionsTemplate"
    [hideIndex]="true"
    (searchEvent)="searchP($event)"
    (getRecords)="getTotalCards($event)"
    (onHandleLabel)="onHandleLabel($event)"
    (filterEvent)="changeFilter($event)"
  ></app-table>
</ng-container>
<ng-container *ngIf="loading2">
  <div class="loading-content">
    <mat-spinner mode="indeterminate"></mat-spinner>
  </div>
</ng-container>

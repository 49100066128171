import {Card} from '@/models/card';
import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Field, TableViewModel} from '@components/models/table';
import {ApiService} from '@services/api.service';
import {AppService} from '@services/app.service';
import {ExcelService} from '@services/excel.service';
import {SwitchService} from '@services/switch.service';
import {ToastrService} from 'ngx-toastr';
import {CallDataCdr, DataCdr} from "@/models/datacdrDto";
import {CDRDto} from "@/models/cdrDto";
import {DataTransaction} from "@/models/transactionDto";
import Swal from "sweetalert2";
import {DataReloadHistory} from "@/models/reloadHistoryDto";

@Component({
  selector: 'app-excel-report',
  templateUrl: './excel-report.component.html',
  styleUrls: ['./excel-report.component.scss']
})
export class ExcelReportComponent implements OnInit {

  dataModel: TableViewModel = {
    // title: 'Tarjetas SIM',
    // description: '',
    filter: {
      page: 1,
      pageSize: 5
    },
    fields: [],
    records: [],
    totalRecords: [],
    showFilters: true,
    enableActions: false
  };
  columscdr: Field[] = [];
  columsdcdr: Field[] = [];
  columstransaction: Field[] = [];
  columsReloadHistory: Field[] = [];

  records: any;
  records_count: number = null;
  recordsModel: TableViewModel = {
    title: 'Records',
    fields: [
      {title: 'Id Sim', key: 'tsimid', filter: false, data: []},
      {title: 'Fecha', key: 'calldate', filter: false, data: []},
      {title: 'Numero', key: 'onum', filter: false, data: []},
      {
        title: 'Codigo de operador',
        key: 'opercode',
        filter: false,
        data: []
      },
      {title: 'Operador', key: 'operator', filter: false, data: []},
      {title: 'Pais', key: 'country', filter: false, data: []},
      {title: 'Consumo (bytes)', key: 'inb', filter: false, data: []},
      {title: 'Costo (USD)', key: 'cost', filter: false, data: []}
    ],
    records: [],
    showFilters: false,
    enableActions: false,
    isModal: true
  };
  dataSource: any = null;
  currentPage: number = 1;
  pageSize: number = 3;
  minDate: string;
  maxDate: string;
  Simcards: Card[] = [];
  SelectSimcards: Card[] = [];
  cards: any = [];
  loadingSimcards: boolean = true;
  simcards_print: string;
  loading: boolean;
  SimcardsForm = new FormControl([], Validators.required);
  created: boolean = false;
  error: boolean = false;
  title: string;
  showTableHistory: boolean = false;
  typeTableHistory: string = 'dcdr';
  showForm: boolean = true;
  resposeDataCdr: DataCdr;
  resposeCdr: CDRDto;
  resposeTransaction: DataTransaction;
  responseReloadHistory: DataReloadHistory;
  sim_select: boolean = false;


  @Input()
  card_selected: any;

  public recordsForm: FormGroup;

  constructor(
    private apiService: ApiService,
    private appService: AppService,
    private cd: ChangeDetectorRef,
    private modalService: SwitchService,
    private toastr: ToastrService,
    private excelService: ExcelService
  ) {
  }

  ngOnInit(): void {
    this.columscdr = [
      {title: 'Fecha', key: 'calldate', filter: false, data: []},
      {title: 'MSISDN', key: 'onum', filter: false, data: []},
      {title: 'Costo MB', key: 'ccost', mb: true, filter: false, data: []}
    ];

    this.columsdcdr = [
      {title: 'Fecha', key: 'calldate', filter: false, data: []},
      {title: 'MSISDN', key: 'onum', filter: false, data: []},
      {title: 'Codigo Operador', key: 'opercode', filter: false, data: []},
      {title: 'Operador', key: 'operator', filter: false, data: []},
      {title: 'Consumo Bytes', key: 'inb', filter: false, data: []}
    ];

    this.columstransaction = [
      {title: 'Fecha', key: 'added', filter: false, data: []},
      {title: 'Tipo', key: 'type', filter: false, data: []},
      {title: 'Valor', key: 'amount', filter: false, data: []},
      {title: 'Tarjeta Sim Relacionada', key: 'onum', filter: false, data: []},
      {title: 'Cuenta relacionada', key: 'uname', filter: false, data: []},
      {title: 'Order Id', key: 'orderid', filter: false, data: []}
    ];

    this.columsReloadHistory = [
      {title: 'Fecha', key: 'added', filter: false, data: []},
      {title: 'MSISDN', key: 'onum', filter: false, data: []},
      {title: 'Amount', key: 'amount', filter: false, data: []},
      {title: 'Balance', key: 'balance', filter: false, data: []},
    ];

    this.apiService.getCardsInfo().then(async (cards) => {
      this.Simcards = cards.result;
      this.SelectSimcards = cards.result;
      this.loadingSimcards = false;
    });
    this.modalService.$created.subscribe((value) => {
      this.created = value;
    });

    this.modalService.$error.subscribe((value) => {
      this.error = value;
    });

    this.recordsForm = new FormGroup({
      startdate: new FormControl(null, Validators.required),
      enddate: new FormControl(null, Validators.required),
      report_type: new FormControl('dcdr', Validators.required)
    });
    let date = new Date();
    let minDate = new Date();
    minDate.setDate(minDate.getDate() - 90);

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    let minDay = minDate.getDate();
    let minMonth = minDate.getMonth() + 1;
    let minYear = minDate.getFullYear();

    if (month < 10) {
      this.maxDate = `${year}-0${month}-${day}`;
    } else {
      this.maxDate = `${year}-${month}-${day}`;
    }

    if (minMonth < 10) {
      this.minDate = `${minYear}-0${minMonth}-${minDay}`;
    } else {
      this.minDate = `${minYear}-${minMonth}-${minDay}`;
    }
  }

  ngAfterViewInit() {
    console.log(this.minDate);
    console.log(this.maxDate);
  }

  searchSim(value: string) {
    this.SelectSimcards = this.Simcards.filter((option) => {
      const itemData = option.onum.toUpperCase();
      const filter = value.toUpperCase();
      return itemData.indexOf(filter) > -1;
    });
  }

  onChageReportType(event) {
    this.typeTableHistory = event;
    if (event === 'reload_history') {
      this.SimcardsForm = new FormControl([]);
      this.sim_select = true;
    }else{
      this.sim_select = false;
    }
    this.cd.detectChanges();
  }

  async getRecords() {
    this.dataModel.records = [];
    var data = {
      title: 'Historial De Simcards',
      header: [],
      data: []
    };
    if (this.recordsForm.valid) {
      this.loading = true;
      var json_print_excel = [];
      if (this.recordsForm.value.report_type === 'reload_history') {
        await this.apiService.getCDR(
          null,
          null,
          null,
          this.recordsForm.value.startdate,
          this.recordsForm.value.enddate,
          this.recordsForm.value.report_type
        )
          .then(async (records) => {
            if (records.result) {
              this.records = records.result;
              this.records_count = records.total_records_count;
              this.recordsModel.records = this.records;
              this.recordsModel.pagination = {
                currentPage: this.currentPage,
                pageSize: this.pageSize,
                pageCount: records.count,
                rowCount: this.records_count
              };
              this.responseReloadHistory = records;
              console.log(this.responseReloadHistory);
              console.log(this.responseReloadHistory.result.records);
              if (this.responseReloadHistory.result.records != null) {
                this.dataModel.fields = this.columsReloadHistory;
                this.dataModel.records = this.responseReloadHistory.result.records.record;
                this.showForm = false;
                this.showTableHistory = true;
              } else {
                this.showMessageErrorRequest();
              }
            } else {
              this.loading = false;
              this.appService.logout();
            }
          }).catch((e) => {
            console.log(e);
          });
      }
      for await (let card of this.SimcardsForm.value) {
        await this.apiService
          .getCDR(
            null,
            null,
            card,
            this.recordsForm.value.startdate,
            this.recordsForm.value.enddate,
            this.recordsForm.value.report_type
          )
          .then(async (records) => {
            if (records.result) {
              this.records = records.result;
              this.records_count = records.total_records_count;
              this.recordsModel.records = this.records;
              this.recordsModel.pagination = {
                currentPage: this.currentPage,
                pageSize: this.pageSize,
                pageCount: records.count,
                rowCount: this.records_count
              };
              // json_print_excel.push(...this.records);

              switch (this.typeTableHistory) {
                case "dcdr":
                  this.resposeDataCdr = records;
                  if (this.resposeDataCdr.result.records != null) {
                    this.dataModel.fields = this.columsdcdr;
                    this.dataModel.records = this.resposeDataCdr.result.records.call;
                    this.showForm = false;
                    this.showTableHistory = true;
                  }
                  break;
                case "cdr":
                  this.resposeCdr = records;
                  if (this.resposeCdr.result.records != null) {
                    this.dataModel.fields = this.columscdr;
                    this.dataModel.records = this.resposeCdr.result.records.sms;
                    this.showForm = false;
                    this.showTableHistory = true;
                  }
                  break;
                case "transactions":
                  this.resposeTransaction = records;
                  if (this.resposeTransaction.result.records != null) {
                    this.dataModel.fields = this.columstransaction;
                    this.dataModel.records.push(this.resposeTransaction.result.records.record[0]);
                    this.dataModel.records.push(this.resposeTransaction.result.records.record[1]);
                    this.showForm = false;
                    this.showTableHistory = true;
                  }
                  break;
              }
            } else {
              this.loading = false;
              this.appService.logout();
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
      if (this.dataModel.records.length === 0){
        this.showMessageErrorRequest();
      }else{
        console.log(this.dataModel.records);
      }
      this.cd.detectChanges();
      for await (let card of json_print_excel) {
        var row = [];
        for await (let field of this.recordsModel.fields) {
          row.push(card[field.key]);
        }
        data.data.push(row);
      }
      this.loading = false;
      // await this.excelService.generateExcel(data);
    } else {
      this.loading = false;
      this.toastr.error('Faltan datos en el formulario!');
    }
  }

  selectAllUnits() {
    var json_units = [];
    for (let card of this.Simcards) {
      json_units.push(card['onum']);
    }
    console.log(json_units);
    this.SimcardsForm.setValue(json_units);
  }

  deleteAllUnits() {
    var json_units = [];
    this.SimcardsForm.setValue(json_units);
  }

  closeModalViewRecords() {
    this.modalService.$modal.emit(false);
  }

  clickReturn(event
                :
                any
  ) {
    this.showTableHistory = false;
    this.showForm = true;
  }

  showMessageErrorRequest() {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'La consulta no arrojo resultados!',
      footer: ''
    })
  }
}

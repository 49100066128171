<div class="modal-back">
    <div class="modal-front column">
        <div class="cardModal-content" *ngIf="loading">
            <img src="assets/icons/motion/motion-small-loader.gif" />
        </div>
        <ng-container *ngIf="!loading">
            <div>
                <ng-container class="cardModal-content" *ngIf="error">
                    <img src="assets/icons/motion/ico-exit.svg" />
                </ng-container>
                <ng-container class="cardModal-content" *ngIf="success">
                    <img src="assets/icons/motion/CreatedIco.svg" />
                </ng-container>
            </div>
            <div className="title">{{ title }}</div>
        </ng-container>
        <div class="modal-close" (click)="close()"></div>
    </div>
</div>

import {Component, Input, OnInit, Output} from '@angular/core';
import {SwitchService} from '@services/switch.service';
import {EventEmitter} from 'protractor';

@Component({
    selector: 'app-modal-loading',
    templateUrl: './modal-loading.component.html',
    styleUrls: ['./modal-loading.component.scss']
})
export class ModalLoadingComponent implements OnInit {
    @Input()
    title: string;
    @Input()
    loading: boolean = false;
    @Input()
    success: boolean;
    @Input()
    error: boolean;

    constructor(private modalService: SwitchService) {}

    ngOnInit(): void {
        this.modalService.$created.subscribe((value) => {
            if (value) {
                console.log('estado creado');
                setTimeout(() => {
                    this.modalService.$created.emit(false);
                }, 3000);
            }
        });
        this.modalService.$error.subscribe((value) => {
          if (value) {
              console.log('estado creado');
              setTimeout(() => {
                  this.modalService.$error.emit(false);
              }, 3000);
          }
      });
    }

    close() {
        // this.callbackFunction();
        this.modalService.$modalLoading.emit(false);
    }
}

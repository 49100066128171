import {HttpContext} from '@angular/common/http';
import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '@services/api.service';
import {AppService} from '@services/app.service';
import {SwitchService} from '@services/switch.service';

@Component({
    selector: 'app-manage-account',
    templateUrl: './manage-account.component.html',
    styleUrls: ['./manage-account.component.scss']
})
export class ManageAccountComponent implements OnInit {
    user: any = null;
    account: any = null;
    messages_count: number = null;
    cards_count: number = null;
    show_edit_account: boolean;
    myAccount: any;
    fileToUpload: string;
    loading_user: boolean = true;
    isClasicTypeAccount: boolean = true;

    public accountForm: FormGroup;

    constructor(
        private cd: ChangeDetectorRef,
        private appService: AppService,
        private apiService: ApiService,
        private modalService: SwitchService
    ) {}

    ngOnInit(): void {
        this.accountForm = new FormGroup({
            name: new FormControl(null, Validators.required),
            email: new FormControl(null, Validators.required),
            telefono: new FormControl(null),
            postal: new FormControl(null),
            company_name: new FormControl(null),
            image: new FormControl(null)
        });
        this.appService
            .getSessionInfo()
            .then((user) => {
              user ? (this.user = user) : this.logout();

            });

        this.appService
            .getAccountInfo()
            .then((account) =>
                account ? (this.account = account) : this.logout()
            );
        this.apiService.getMyAccountInfo().then((account) => {
            this.accountForm.controls['name'].setValue(
                account.name ? account.name : ''
            );
            this.accountForm.controls['email'].setValue(
                account.email ? account.email : ''
            );
            this.accountForm.controls['telefono'].setValue(
                account.telefono ? account.telefono : ''
            );
            this.accountForm.controls['postal'].setValue(
                account.postal ? account.postal : ''
            );
            this.accountForm.controls['company_name'].setValue(
                account.company_name ? account.company_name : ''
            );
            this.accountForm.controls['image'].setValue(
                account.image ? account.image : ''
            );
            this.fileToUpload = account.image;

            account ? (this.myAccount = account) : this.logout();
        });
        this.modalService.$modal.subscribe((value) => {
            this.show_edit_account = value;
        });
        setTimeout(()=>{
          this.loading_user = false;
          this.cd.detectChanges();
        }, 10000);
    }

    handleFileInput(files: FileList) {
        // this.fileToUpload = files.item(0);
        console.log(files.item(0));

        const file = files.item(0);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
            const str = String(e.target.result);
            const img = str.slice(23);
            this.fileToUpload = img;
            this.accountForm.controls['image'].setValue(img);
        };
    }

    editAccount() {
        if (this.accountForm.valid) {
            this.apiService
                .postMyAccountInfo(this.accountForm.value)
                .then((response) => {
                    if (response.error) {
                        console.log('error');
                    } else {
                        this.modalService.$modal.emit(false);
                    }
                });
        } else {
            console.log('faltan datos');
        }
    }

    ngAfterViewInit() {
        this.apiService.getMessageInfo(1, 1).then((messages) => {
            if (messages.result) {
                this.messages_count = messages.count;
            }
        });
        this.apiService.getCardsInfo(1, 1).then((cards) => {
            if (cards.result) {
                this.cards_count = cards.total_cards_count;
            }
        });
    }

    showEditAccount() {
        this.modalService.$modal.emit(true);
    }

    logout() {
        this.appService.logout();
    }

    toggleTypeServiceAccount() {
        this.isClasicTypeAccount = !this.isClasicTypeAccount;
        if (this.isClasicTypeAccount) {
            this.apiService.getCardsInfo(1, 1).then((cards) => {
                if (cards.result) {
                    this.cards_count = cards.total_cards_count;
                }
            });    
        }else {
            this.apiService.getTruphoneCardsInfo().then((cards)=>{
                this.cards_count = cards.total_cards_count;
              })
        }
    }
}

import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {Gatekeeper} from 'gatekeeper-client-sdk';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class AppService {
    public user: any = null;

    public account: any = null;

    constructor(
        private router: Router,
        private toastr: ToastrService,
        private http: HttpClient
    ) {}

    async loginByAuth({email, password}) {
        try {
            let body = {
                jsonrpc: '2.0',
                params: {
                    login: email,
                    password: password,
                    db: 'plataforma_motion'
                }
            };

            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': 'http://192.168.0.12:4002'
            });

            this.http
                .post<any>('/api/misim/auth/', body, {headers: headers})
                .subscribe((data) => {
                    console.log(data);
                    if (data.hasOwnProperty('error')) {
                        console.log('Credenciales invalidas!!!');
                        console.log(data.error.data.message);
                        this.toastr.error(data.error.data.message);
                    } else {
                        if (data.result.hasOwnProperty('error')) {
                            console.log('`No tienes acceso!');
                            this.toastr.error(data.result.error.data.message);
                        } else if (data.result.uid > 0) {
                            console.log('Login exitoso!');
                            console.log(data.result);
                            // localStorage.setItem('sessionId', data.result);
                            this.saveUserInfo(data.result);
                            this.router.navigate(['/']);
                        } else {
                            this.toastr.error(
                                'Algo salio mal, intentalo de nuevo.'
                            );
                        }
                    }
                });
            // const token = await Gatekeeper.loginByAuth(email, password);
            // localStorage.setItem('token', token);
            // await this.getProfile();
            // this.router.navigate(['/']);
        } catch (error) {
            this.toastr.error(error.response.data.message);
        }
    }

    async registerByAuth({email, password}) {
        try {
            const token = await Gatekeeper.registerByAuth(email, password);
            localStorage.setItem('token', token);
            await this.getProfile();
            this.router.navigate(['/']);
        } catch (error) {
            this.toastr.error(error.response.data.message);
        }
    }

    async loginByGoogle() {
        try {
            const token = await Gatekeeper.loginByGoogle();
            localStorage.setItem('token', token);
            await this.getProfile();
            this.router.navigate(['/']);
        } catch (error) {
            this.toastr.error(error.response.data.message);
        }
    }

    async registerByGoogle() {
        try {
            const token = await Gatekeeper.registerByGoogle();
            localStorage.setItem('token', token);
            await this.getProfile();
            this.router.navigate(['/']);
        } catch (error) {
            this.toastr.error(error.response.data.message);
        }
    }

    async loginByFacebook() {
        try {
            const token = await Gatekeeper.loginByFacebook();
            localStorage.setItem('token', token);
            await this.getProfile();
            this.router.navigate(['/']);
        } catch (error) {
            this.toastr.error(error.response.data.message);
        }
    }

    async registerByFacebook() {
        try {
            const token = await Gatekeeper.registerByFacebook();
            localStorage.setItem('token', token);
            await this.getProfile();
            this.router.navigate(['/']);
        } catch (error) {
            this.toastr.error(error.response.data.message);
        }
    }

    async getProfile() {
        try {
            this.user = await this.getSessionInfo();
            if (this.user){
              return true;
            }else{
              this.logout();
              return false;
            }
        } catch (error) {
            this.logout();
            throw error;
        }
    }

    async logout() {
        let res = await this.http.get<any>('/api/misim/logout').toPromise();
        console.log(res);
        this.user = null;
        await this.router.navigate(['/login']);
    }

    async getSessionInfo() {
        let user = await this.http.get<any>('/api/misim/session').toPromise();
        if (user.uid != null) {
            return user;
        } else {
            return null;
        }
    }

    async getAccountInfo() {
        let account = await this.http
            .get<any>('/api/misim/account')
            .toPromise();
        if (account.activation_date != null) {
            this.account = account;
            return account;
        } else {
            return null;
        }
    }

    private saveUserInfo(result) {
        this.user = result;
    }
}

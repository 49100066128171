<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h2 class="title-main">Mi cuenta</h2>
                <button (click)="toggleTypeServiceAccount()" [ngClass]="{'active': isClasicTypeAccount}">
                    {{ isClasicTypeAccount ? 'Tarjetas clasicas' : 'Tarjetas con plan' }}
                  </button>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">Cuentas</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <div class="container-fluid">
        <div class="mannageAccouunt-content" *ngIf="!loading_user">
            <div class="cardInfo-user">
                <div class="cardInfo-content">
                    <div class="cardInfo-co1">
                        <ng-container *ngIf="!loading_user">
                          <ng-container *ngIf="user && user.image">
                            <img
                              *ngIf="!loading_user"
                              src="{{
                                    'data:image/jpg;base64,' + user.image
                                }}"
                              class="profile-user-img img-fluid img-circle"
                              alt="User Image"
                            />
                          </ng-container>
                          <ng-container *ngIf="!user || !user.image">
                            <img
                              src="./assets/images/avatars/0.png"
                              class="profile-user-img img-fluid img-circle"
                              alt="User Image"
                            />
                          </ng-container>
                          <div class="cardInfo-co1-des">
                            <div class="cardInfo-co1-user">{{ user.name }}</div>
                            <div class="cardInfo-co1-email">
                              {{ user.username }}
                            </div>
                          </div>
                        </ng-container>

                    </div>
                    <div class="cardInfo-co2">
                        <div class="cardInfo-co2-des">
                            <div class="cardInfo-co2-number">
                                {{ cards_count }}
                            </div>
                            <div class="cardInfo-co2-name">Tarjetas sim</div>
                        </div>
                        <div class="cardInfo-co2-des">
                            <div class="cardInfo-co2-number">
                                {{ messages_count }}
                            </div>
                            <div class="cardInfo-co2-name">Mensajes</div>
                        </div>
                    </div>
                </div>
                <div class="row cardInfo-footer" (click)="showEditAccount()">
                    <div class="cardInfo-footer-text">Editar</div>
                    <div class="icon-td">
                        <svg
                            version="1.1"
                            id="listos"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            viewBox="0 0 100 100"
                            style="enable-background: new 0 0 100 100"
                            xml:space="preserve"
                            class="table-icon"
                        >
                            <path
                                fill="#ffffff"
                                d="M80,60c0,22.1-17.9,40-40,40S0,82.1,0,60c0-22.1,17.9-40,40-40c2.8,0,5.5,0.3,8.1,0.8L40.9,28
	c-0.3,0-0.6,0-0.9,0C22.4,28,8,42.4,8,60s14.4,32,32,32s32-14.4,32-32c0-0.3,0-0.6,0-0.9l7.2-7.2C79.7,54.5,80,57.2,80,60z M80.2,0
	L33.9,46.3l19.8,19.8L100,19.8L80.2,0z M28.2,52L24,76l24-4.2L28.2,52L28.2,52z"
                            />
                        </svg>
                    </div>
                </div>
            </div>
            <div class="cardInfo-account">
                <ng-container *ngIf="!loading_user">
                  <div class="cardInfo-header">Información de la cuenta</div>
                  <div class="cardInfo-content">
                    <div class="cardInfo-content-col">
                      <div class="cardInfo-content-number">
                        {{
                        account.activation_date !== 'False' &&
                        account.activation_date !== false
                          ? account.activation_date
                          : '-'
                        }}
                      </div>
                      <div class="cardInfo-content-name">
                        Fecha de activacion
                      </div>
                    </div>
                    <div class="cardInfo-content-col">
                      <div class="cardInfo-content-number">
                        {{
                        account.expiry_date !== 'False' &&
                        account.expiry_date !== false
                          ? account.expiry_date
                          : '-'
                        }}
                      </div>
                      <div class="cardInfo-content-name">
                        Fecha de expiracion
                      </div>
                    </div>
                    <div
                    class="cardInfo-content-col">
                      <div [ngClass]="{'cardInfo-content-number': isClasicTypeAccount, 'cardInfo-content-number color_transparent': !isClasicTypeAccount}">
                        {{ account.data_balance }}
                        MB
                      </div>
                      <div [ngClass]="{'cardInfo-content-number': isClasicTypeAccount, 'cardInfo-content-number color_transparent': !isClasicTypeAccount}">
                        Balance de datos
                      </div>
                    </div>
                    <div class="cardInfo-content-col">
                      <div class="cardInfo-content-number">USD</div>
                      <div class="cardInfo-content-name">Moneda</div>
                    </div>
                  </div>
                </ng-container>
            </div>
        </div>
        <ng-container *ngIf="loading_user">
          <div class="loading-content" >
            <mat-spinner mode="indeterminate"></mat-spinner>
          </div>
        </ng-container>
    </div>
    <ng-template [ngIf]="show_edit_account === true">
        <app-modal>
            <div>
                <form
                    class="account-form"
                    [formGroup]="accountForm"
                    (ngSubmit)="editAccount()"
                >
                    <div class="row account-form-header">
                        <div class="image">
                            <div class="form-group">
                                <ng-container *ngIf="account">
                                    <img
                                        class="
                                            profile-user-img
                                            img-fluid img-circle
                                        "
                                        src="{{
                                            'data:image/jpg;base64,' +
                                                fileToUpload
                                        }}"
                                        alt="User profile picture"
                                    />
                                </ng-container>
                                <label for="file" class="edit-ico-content">
                                    <div class="center-content">
                                        <img
                                            src="assets/icons/Clothes/motion_icon-camara.svg"
                                            alt=""
                                            class="edit-ico"
                                        />
                                    </div>
                                </label>
                                <input
                                    type="file"
                                    id="file"
                                    class="account-input-file"
                                    accept="image/png, image/jpeg"
                                    formControlName="image"
                                    (change)="
                                        handleFileInput($event.target.files)
                                    "
                                />
                            </div>
                        </div>
                        <div class="column account-form-footer">
                            <div>
                                <button class="btn btn1" [type]="'submit'">
                                    Guardar
                                </button>
                            </div>
                            <div>
                                <button class="btn btn2">Cancelar</button>
                            </div>
                        </div>
                    </div>
                    <div class="account-form-body">
                        <div class="item-form column">
                            <label class="label-input" for="input-name"
                                >Nombre</label
                            >
                            <input
                                type="text"
                                formControlName="name"
                                name="name"
                                placeholder="Nombre"
                                class="input-form"
                                id="input-name"
                            />
                        </div>
                        <div class="item-form column">
                            <label class="label-input" for="input-email"
                                >Email</label
                            >
                            <input
                                type="email"
                                formControlName="email"
                                name="email"
                                placeholder="Email"
                                class="input-form"
                                id="input-email"
                                [readonly]="true"
                            />
                        </div>
                        <div class="item-form column">
                            <label class="label-input" for="input-phone"
                                >Teléfono</label
                            >
                            <input
                                type="text"
                                formControlName="telefono"
                                name="telefono"
                                placeholder="teléfono"
                                class="input-form"
                                id="input-phone"
                            />
                        </div>
                        <div class="item-form column">
                            <label class="label-input" for="input-postal"
                                >Postal</label
                            >
                            <input
                                type="text"
                                name="postal"
                                formControlName="postal"
                                placeholder="codigo postal"
                                class="input-form"
                                id="input-postal"
                            />
                        </div>
                    </div>
                </form>
            </div>
        </app-modal>
    </ng-template>
    <!-- /.container-fluid -->
</section>

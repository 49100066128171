import {Message} from '@angular/compiler/src/i18n/i18n_ast';
import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {TableViewModel} from '@components/models/table';
import {PaginationService} from '@components/pagination/pagination.service';
import {ApiService} from '@services/api.service';
import {AppService} from '@services/app.service';
import {ExcelService} from '@services/excel.service';

@Component({
    selector: 'app-manage-messages',
    templateUrl: './manage-messages.component.html',
    styleUrls: ['./manage-messages.component.scss']
})
export class ManageMessagesComponent implements OnInit {
    currentPage: number = 1;
    pageSize: number = 5;
    messages: Message[] = null;
    messages_count: number = null;
    messageModel: TableViewModel = {
        title: 'Mensajes',
        description: '',
        filter: {
            page: 1,
            pageSize: 5
        },
        fields: [
            {title: 'Fecha', key: 'date', filter: false, data: []},
            {title: 'Mensaje', key: 'msg', filter: false, data: []},
            {title: 'Emisor', key: 'from', filter: false, data: []},
            {title: 'Receptor', key: 'to', filter: false, data: []},

        ],
        records: [],
        showFilters: true,
        enableActions: false
    };
    searchby: string = '';
    constructor(
        private apiService: ApiService,
        private appService: AppService,
        private cd: ChangeDetectorRef,
        private pS: PaginationService,
        private excelService: ExcelService
    ) {}

    ngOnInit(): void {
      console.log('Init messages')
    }

    getMessages(currentPage, searchby) {
        this.apiService
            .getMessageInfo(currentPage, this.pageSize, searchby)
            .then((messages) => {
                if (messages.count > 0) {
                    this.messages = messages.result;
                    this.messages_count = messages.count;
                    this.messageModel.records = this.messages;
                    this.messageModel.pagination = {
                        currentPage: this.currentPage,
                        pageSize: this.pageSize,
                        pageCount: messages.count,
                        rowCount: this.messages_count
                    };
                    this.cd.detectChanges();
                }
            });
    }

    getTotalMessages(header: any) {
        this.apiService
            .getMessageInfo(this.currentPage, this.pageSize)
            .then(async (messages) => {
                if (messages.result) {
                    this.messageModel.totalRecords = messages.result;
                    var data = {
                        title: header.title,
                        header: header.header,
                        data: []
                    };
                    for await (let message of messages.result) {
                        var row = [];
                        for await (let key of header.keys) {
                            row.push(message[key]);
                        }
                        data.data.push(row);
                    }
                    await this.excelService.generateExcel(data);
                } else {
                    this.logout();
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    ngAfterViewInit() {
        this.getMessages(this.currentPage, this.searchby);
    }

    logout() {
        this.appService.logout();
    }

    onRemove(index: number) {
        console.log('remove event ! ');
    }

    paginatorEvent(currentPage: number) {
        this.getMessages(currentPage, this.searchby);
    }

    pageSizeEvent(pageSize: number) {
        this.pageSize = pageSize;
        this.paginatorEvent(this.currentPage);
    }

    searchP(searchby: string) {
        this.getMessages(this.currentPage, searchby ? searchby : this.searchby);
    }
}
